import type { ReactElement } from "react";
import { useState, useEffect, useRef, useCallback } from "react";
import { Button, Container, Grid2 as Grid, Typography } from "@mui/material";
import type { Accident, OfflineAccident } from "models/Accident";
import accidentService from "services/accidentService";
import declarationOfflineService from "services/declarationOfflineService";
import declarationSyncService from "services/declarationSyncService";
import { useLiveQuery } from "dexie-react-hooks";
import AddIcon from "@mui/icons-material/Add";
import { grey } from "@mui/material/colors";
import { routesConfig } from "app-config";
import type { OfflineSecurite, Securite } from "models/Securite";
import securiteService from "services/securiteService";
import DividerTitle from "components/utils/DividerTitle";
import ApplicationRoles from "constants/ApplicationRoles";
import { useUser } from "providers/Authentication/SecuUserProvider";
import dangerService from "services/dangerService";
import type { DangerGrave, OfflineDangerGrave } from "models/DangerGrave";
import DeclarationsGrid from "components/declarationsGrid/DeclarationsGrid";
import { enqueueSnackbar } from "notistack";
import ToastMessages from "constants/ToastMessages";

function MainPage(): ReactElement {
  const [accidents, setAccidents] = useState<Array<Accident>>([]);
  const [securites, setSecurites] = useState<Array<Securite>>([]);
  const [dgis, setDgis] = useState<Array<DangerGrave>>([]);
  const previousOfflineAccidents = useRef<Array<OfflineAccident>>([]);
  const previousOfflineSecurites = useRef<Array<OfflineSecurite>>([]);
  const previousOfflineDgis = useRef<Array<OfflineDangerGrave>>([]);
  const [isAccidentsLoading, setIsAccidentsLoading] = useState<boolean>(true);
  const [isSecuritesLoading, setIsSecuritesLoading] = useState<boolean>(true);
  const [isDgisLoading, setIsDgisLoading] = useState<boolean>(true);
  const { isRoles } = useUser();

  /** Requête l'IndexedDB en utilisant l'API de dexie.
   * Le résultat devient magiquement observable (!) */
  const offlineAccidents = useLiveQuery(async () => {
    return declarationOfflineService.getAllAccidents();
  });
  const offlineSecurites = useLiveQuery(async () => {
    return declarationOfflineService.getAllSecurites();
  });
  const offlineDgis = useLiveQuery(async () => {
    return declarationOfflineService.getAllDangerGrave();
  });

  const updateAllDeclarations = useCallback((): void => {
    async function updateAccidents(): Promise<void> {
      setIsAccidentsLoading(true);
      try {
        const allAccidents = isRoles(ApplicationRoles.MODERATOR)
          ? await accidentService.getAll()
          : await accidentService.getAllForSubordinatesAndCurrentUser();
        setAccidents(allAccidents);
      } catch {
        enqueueSnackbar(ToastMessages.ERROR_DECLARATIONS, {
          variant: "error",
        });
        setAccidents([]);
      }
      setIsAccidentsLoading(false);
    }

    async function updateSecurites(): Promise<void> {
      setIsSecuritesLoading(true);
      try {
        const allSecurites = isRoles(ApplicationRoles.MODERATOR)
          ? await securiteService.getAll()
          : await securiteService.getAllForCurrentUser();
        setSecurites(allSecurites);
      } catch {
        enqueueSnackbar(ToastMessages.ERROR_DECLARATIONS, {
          variant: "error",
        });
        setSecurites([]);
      }
      setIsSecuritesLoading(false);
    }

    async function updateDgis(): Promise<void> {
      setIsDgisLoading(true);
      try {
        const allDgis = isRoles(ApplicationRoles.MODERATOR)
          ? await dangerService.getAll()
          : await dangerService.getAllForCurrentUser();
        setDgis(allDgis);
      } catch {
        enqueueSnackbar(ToastMessages.ERROR_DECLARATIONS, {
          variant: "error",
        });
        setDgis([]);
      }
      setIsDgisLoading(false);
    }

    void updateAccidents();
    void updateSecurites();
    void updateDgis();
  }, [isRoles]);

  useEffect(() => {
    async function synchronizeAndUpdateView(): Promise<void> {
      if (await declarationSyncService.synchronizeOfflineAndOnline()) {
        updateAllDeclarations();
      }
    }

    updateAllDeclarations();
    void synchronizeAndUpdateView();
  }, [updateAllDeclarations]);

  useEffect(() => {
    if (
      JSON.stringify(offlineAccidents ?? []) !== JSON.stringify(previousOfflineAccidents.current)
    ) {
      updateAllDeclarations();
    }
    previousOfflineAccidents.current = offlineAccidents ?? [];
  }, [offlineAccidents, updateAllDeclarations]);

  useEffect(() => {
    if (
      JSON.stringify(offlineSecurites ?? []) !== JSON.stringify(previousOfflineSecurites.current)
    ) {
      updateAllDeclarations();
    }
    previousOfflineSecurites.current = offlineSecurites ?? [];
  }, [offlineSecurites, updateAllDeclarations]);

  useEffect(() => {
    if (JSON.stringify(offlineDgis ?? []) !== JSON.stringify(previousOfflineDgis.current)) {
      updateAllDeclarations();
    }
    previousOfflineDgis.current = offlineDgis ?? [];
  }, [offlineDgis, updateAllDeclarations]);

  return (
    <Container maxWidth="xl">
      <Grid container spacing={3} size={12}>
        <Grid container size={12} spacing={3}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Button variant="contained" href={routesConfig.new.path} startIcon={<AddIcon />}>
              Créer une nouvelle déclaration
            </Button>
          </Grid>
        </Grid>

        <Grid size={12}>
          <DividerTitle>
            <Typography variant="h5">Récapitulatif des Déclarations</Typography>
          </DividerTitle>
        </Grid>

        <Grid size={12}>
          <Typography sx={{ color: grey[500] }}>
            Cliquez sur une ligne du tableau pour
            {isRoles(ApplicationRoles.MODERATOR) ? " modifier " : " consulter "}
            la déclaration correspondante
          </Typography>
        </Grid>

        <Grid size={12}>
          <DeclarationsGrid
            accidents={accidents}
            securites={securites}
            dgis={dgis}
            offlineAccidents={offlineAccidents}
            offlineSecurites={offlineSecurites}
            offlineDgis={offlineDgis}
            isDeclarationsLoading={isAccidentsLoading || isSecuritesLoading || isDgisLoading}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default MainPage;
