import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import { Container, Grid2 as Grid, useMediaQuery, useTheme } from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import SecuriteForm from "components/sections/Forms/SecuriteForm";
import { useData } from "providers/DataProvider";
import type { Securite } from "models/Securite";
import SecuriteService from "services/securiteService";
import type TypeSecurite from "constants/TypeSecurite";
import DeclarationFormTitle from "components/sections/Forms/DeclarationFormTitle";
import DeclarationDetails from "components/sections/DeclarationDetails/DeclarationDetails";
import ErrorPage from "components/errors/ErrorPage";
import ServerErrorPage from "components/errors/ServerErrorPage";
import NotFoundErrorPage from "components/errors/NotFoundErrorPage";
import ForbiddenErrorPage from "components/errors/ForbiddenErrorPage";
import Errors from "constants/Errors";

function SecuritePage({ typeSecurite }: { typeSecurite?: TypeSecurite }): ReactElement {
  const { declarationId } = useParams();
  const [isDeclarationLoading, setIsDeclarationLoading] = useState(true);
  const [isNewDeclaration, setIsNewDeclaration] = useState(true);
  const [isRequalifying, setIsRequalifying] = useState(false);
  const [securite, setSecurite] = useState<Securite>();
  const [error, setError] = useState<Error>();
  const { error: dataError } = useData();
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    setIsNewDeclaration(declarationId == null);
    if (declarationId != null) {
      if (declarationId !== securite?.id) {
        void fetchDeclaration(declarationId);
      }
    } else {
      if (location?.state != null) {
        const { requalifyingDeclaration }: { requalifyingDeclaration: Securite | undefined } =
          location.state;

        if (requalifyingDeclaration != null) {
          setIsDeclarationLoading(true);
          setIsRequalifying(true);
          setSecurite(requalifyingDeclaration);
        }
        setTimeout(() => {
          setIsDeclarationLoading(false);
        }, 100);
      } else {
        setIsDeclarationLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (dataError) {
      setError(new Error());
    }
  }, [dataError]);

  async function fetchDeclaration(id: string): Promise<void> {
    setIsDeclarationLoading(true);

    try {
      const dec = await SecuriteService.getById(id);
      setSecurite(dec);
      setIsDeclarationLoading(false);
    } catch (error: unknown) {
      console.error("Declaration (probably) not found !", error);
      setError(error as Error);
    }
  }

  function updateReferenceDeclaration(newReferenceDeclaration: Securite): void {
    setSecurite(newReferenceDeclaration);
  }

  if (error != null) {
    switch (error?.message) {
      case Errors.FORBIDDEN:
        return <ForbiddenErrorPage />;
      case Errors.NOT_FOUND:
        return (
          <NotFoundErrorPage message="Ceci peut arriver lorsqu'une déclaration a été supprimée ou requalifiée, par exemple." />
        );
      case Errors.SERVER_ERROR:
        return <ServerErrorPage />;
      default:
        return <ErrorPage message={error?.message ?? ""} />;
    }
  }

  return (
    <Container maxWidth="md">
      <Grid container size={12} spacing={2}>
        <Grid size={12}>
          <DeclarationFormTitle
            isRequalifying={isRequalifying}
            isNewDeclaration={isNewDeclaration}
            declarationType={typeSecurite ?? securite?.type}
            displayNumber={securite?.displayNumber}
            isSmallScreen={isSmallScreen}
          />
        </Grid>
        <Grid size={12}>
          <DeclarationDetails
            isNew={isNewDeclaration}
            isSmallScreen={isSmallScreen}
            isLoading={isDeclarationLoading}
            declaration={securite}
            isRequalifying={isRequalifying}
          />
        </Grid>
        <Grid size={12}>
          <SecuriteForm
            isNew={isNewDeclaration}
            isRequalification={isRequalifying}
            securite={securite}
            isLoading={isDeclarationLoading}
            updateReferenceDeclaration={updateReferenceDeclaration}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
            typeSecurite={typeSecurite ?? securite?.type!}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default SecuritePage;
