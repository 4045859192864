import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import {
  Box,
  Button,
  CardActionArea,
  CircularProgress,
  Grid2 as Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useParams } from "react-router-dom";
import photoService from "services/photoService";
import ImageZoomModal from "components/inputs/photos/ImageZoomModal";
import { defaultErrorHandlingPages } from "components/errors/errorHandling";
import BackButton from "components/buttons/BackButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

function PhotoPage(): ReactElement {
  const { photoUrl } = useParams();
  const [isPhotoLoading, setIsPhotoLoading] = useState(true);
  const [openZoomModal, setOpenZoomModal] = useState<boolean>(false);
  const [photoUri, setPhotoUri] = useState("");
  const [error, setError] = useState<Error>();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (photoUrl != null) {
      void fetchPhoto(photoUrl);
    }
  }, [photoUrl]);

  function handleClick(): void {
    setOpenZoomModal(true);
  }

  async function fetchPhoto(url: string): Promise<void> {
    setIsPhotoLoading(true);
    try {
      const uri: string = await photoService.getUriByUrl(url);
      if (uri == null) {
        throw new Error("photoService getUriByUrl response undefined");
      }
      setPhotoUri(uri);
      setIsPhotoLoading(false);
    } catch (error: unknown) {
      console.error("Photo (probably) not found !", error);
      setError(error as Error);
    }
  }

  if (error !== undefined) {
    return defaultErrorHandlingPages(error);
  }

  const imageBoxStyle = {
    width: "100%",
    height: "100%",
    backgroundImage: `url("${photoUri ?? ""}")`,
    backgroundSize: "contain",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  };

  function getPhotoNameFromUri(uri: string): string | undefined {
    return new URL(uri).pathname.split("/").pop();
  }

  return (
    <>
      <Grid
        container
        direction="row"
        sx={{
          mb: 2,
          alignItems: isPhotoLoading ? "center" : "stretch",
          justifyContent: "center",
          textAlign: "center",
          width: "100%",
        }}>
        <Grid container size={12} sx={{ maxWidth: "md", textAlign: "center" }}>
          <BackButton toHomePage />
          <Typography variant={isSmallScreen ? "h4" : "h3"} component="div">
            Photo
          </Typography>
          <Grid
            sx={{
              flexGrow: 1,
              display: "inline-block",
              textAlign: "right",
              marginX: 2,
            }}>
            <Button
              variant="contained"
              startIcon={<FileDownloadIcon />}
              // download attribute only works for same-origin URLs :'(
              download={isPhotoLoading ? "" : getPhotoNameFromUri(photoUri)}
              target="_blank"
              href={photoUri}
              disabled={isPhotoLoading}>
              Télécharger l'image
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        sx={{
          alignItems: isPhotoLoading ? "center" : "stretch",
          justifyContent: "center",
          textAlign: "center",
          width: "100%",
          mb: 2,
          flexGrow: 1,
        }}>
        {isPhotoLoading ? (
          <Grid size={12}>
            <CircularProgress size="150px" />
          </Grid>
        ) : (
          <Grid size={12}>
            <CardActionArea onClick={handleClick} sx={{ cursor: "zoom-in", height: "100%" }}>
              <Box sx={imageBoxStyle} />
            </CardActionArea>
          </Grid>
        )}
        <ImageZoomModal
          altText="Image illustrant les circonstances de l'accident"
          src={photoUri}
          open={openZoomModal}
          setOpen={setOpenZoomModal}
        />
      </Grid>
    </>
  );
}

export default PhotoPage;
