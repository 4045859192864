import { useState, type ChangeEvent, useEffect, useRef, type ReactElement } from "react";
import {
  type ButtonBaseActions,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid2 as Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import type { RefCallBack } from "react-hook-form";

interface JoursTravaillesCheckboxesProps {
  onChange: (a: Array<string>) => void;
  label?: string;
  defaultValue?: Array<string>;
  error?: boolean;
  helperText?: string;
  readOnly?: boolean;
  inputRef?: RefCallBack;
}

/**
 * Affiche un ensemble de checkboxes permettant de sélectionner les jours de la semaine travaillés
 * @param param0 Properties
 * @returns Grid > Checkboxes
 */
function JoursTravaillesCheckboxes({
  onChange,
  label = "",
  defaultValue = [],
  error = false,
  helperText = "",
  readOnly = false,
  inputRef,
}: JoursTravaillesCheckboxesProps): ReactElement {
  const [checked, setChecked] = useState([false, false, false, false, false, false, false]);
  const [days, setDays] = useState(["", "", "", "", "", "", ""]);
  const action = useRef<ButtonBaseActions>(null);

  function handleChange(event: ChangeEvent<HTMLInputElement>, index: number): void {
    if (!readOnly) {
      const isChecked = event.target.checked;
      const newChecked = [...checked];
      newChecked[index] = isChecked;
      setChecked(newChecked);
      const newDays = [...days];
      newDays[index] = isChecked ? event.target.value : "";
      setDays(newDays);
    }
  }

  useEffect((): void => {
    const tempDays = days.join(" ").replace(/\s+/g, " ").trim().split(" ");
    onChange(tempDays);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked, days]);

  const daysOfTheWeek = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"];

  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("md"));

  useEffect(() => {
    /**
     * Initialise les checkboxes avec les données de la bdd
     * @param defaultValues
     */
    function initializeDefaultValues(defaultValues: Array<string>): void {
      const newChecked = [...checked];
      const newDays = [...days];
      daysOfTheWeek.forEach((day, index) => {
        if (defaultValues.includes(day.substring(0, 3))) {
          newChecked[index] = true;
          newDays[index] = day.substring(0, 3);
        }
      });
      setChecked(newChecked);
      setDays(newDays);
    }

    initializeDefaultValues(defaultValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormControl fullWidth error={error}>
      <Grid
        container
        size={12}
        columns={{ xs: 4, sm: 7 }}
        sx={{
          justifyContent: "center",
          textAlign: "center",
          alignContent: "center",
        }}>
        <Grid size={12} sx={{ textAlign: "left" }}>
          <FormLabel component="legend">{label}</FormLabel>
        </Grid>
        {daysOfTheWeek.map((day: string, index: number) => {
          return (
            <Grid key={day.substring(0, 3)} size={{ xs: 1, sm: 1 }}>
              <FormControlLabel
                value={day.substring(0, 3)}
                control={
                  <Checkbox
                    // on passe la ref à la première checkbox, c'est elle qui sera focusée
                    {...(index === 0
                      ? {
                          inputRef,
                          action,
                          // eslint-disable-next-line @typescript-eslint/unbound-method
                          onFocus: action?.current?.focusVisible,
                        }
                      : {})}
                    checked={checked[index]}
                    onChange={(e) => {
                      handleChange(e, index);
                    }}
                    icon={<CircleOutlinedIcon />}
                    checkedIcon={<CheckCircleIcon />}
                  />
                }
                label={isLargeScreen ? day : day.substring(0, 3)}
                labelPlacement="bottom"
              />
            </Grid>
          );
        })}
        <FormHelperText>{helperText}</FormHelperText>
      </Grid>
    </FormControl>
  );
}

export default JoursTravaillesCheckboxes;
