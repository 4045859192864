import { Grid2 as Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import SkeletonInput from "./SkeletonInput";
import SkeletonTitle from "./SkeletonTitle";
import type { ReactElement } from "react";

function SkeletonForm(): ReactElement {
  return (
    <Grid container size={12} rowSpacing={1} columnSpacing={3}>
      <Grid size={12}>
        <Typography variant="caption" gutterBottom sx={{ color: grey[500] }}>
          Les champs suivis d'un astérisque ( * ) sont obligatoires
        </Typography>
      </Grid>
      <SkeletonInput xs={12} sm={6} />
      <SkeletonInput xs={12} sm={6} />
      <SkeletonTitle />
      <SkeletonInput />
      <SkeletonInput />
      <SkeletonInput />
      <SkeletonInput />
      <SkeletonInput />
      <SkeletonTitle />
      <SkeletonInput />
      <SkeletonTitle />
      <SkeletonInput />
      <SkeletonInput />
      <SkeletonInput />
      <SkeletonInput />
    </Grid>
  );
}

export default SkeletonForm;
