import { useEffect, useRef, useState } from "react";
import type { ReactElement } from "react";
import { Grid2 as Grid, useMediaQuery, useTheme } from "@mui/material";
import PhotoInput from "components/inputs/photos/PhotoInput";
import type Image from "models/Image";
import imageCompression from "browser-image-compression";

type PhotosInputProps = {
  value: Array<string>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: Array<any>) => void;
  maximumPhotos?: number;
  readOnly?: boolean;
};
function PhotosInput({
  value,
  onChange,
  maximumPhotos = 3,
  readOnly = false,
}: PhotosInputProps): ReactElement {
  const [images, setImages] = useState<Array<Image>>([]);
  const inputFileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (readOnly) {
      const fakeImageArray: Array<Image> = [];
      value.forEach((base64String) => {
        fakeImageArray.push({
          file: new File([], ""),
          base64String,
        });
      });
      setImages(fakeImageArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const base64Array: Array<string> = [];
    images.forEach((image) => {
      if (image.base64String != null && image.base64String !== "") {
        base64Array.push(image.base64String);
      }
    });
    onChange(base64Array);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [images]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async function handleImageUpload(ev: any): Promise<void> {
    if (readOnly) return;
    type FileEventTarget = EventTarget & { files: FileList };
    /* Selected files data can be collected here. */
    const e = ev as Event;
    if ((e.target as HTMLInputElement)?.files?.length != null) {
      const eTarget = e.target as FileEventTarget;
      const imageFile = eTarget.files[0];

      if (images.length < maximumPhotos) {
        const newArray = [...images];
        const image: Image = {
          file: new File([], ""),
          isLoading: true,
        };
        const index = newArray.push(image) - 1;
        setImages(newArray);

        const options = {
          maxSizeMB: 0.1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        try {
          const base64CompressedImage = await imageCompression.getDataUrlFromFile(
            await imageCompression(imageFile, options)
          );
          const newImages = [...images];
          const newImage: Image = {
            file: imageFile,
            base64String: base64CompressedImage,
            isLoading: false,
          };
          newImages[index] = newImage;
          setImages(newImages);
        } catch (error) {
          console.error("Image compression error :");
          console.error(error);
        }
      } else {
        console.warn("PhotosInput : new photo index > maximumPhotos");
      }
    }
    /* On nettoie la sélection actuelle de l'input pour la prochaine sélection */
    ev.target.value = null;
  }

  function selectImage(): void {
    /* Clique artificiellement sur l'input type='file' */
    inputFileRef?.current?.click();
  }

  function deleteImage(index: number): void {
    if (images[index] != null) {
      const newImages = [...images];
      newImages.splice(index, 1);
      setImages(newImages);
    }
  }

  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      {/* cet input caché est celui qui est artificiellement clické pour
        permettre au user de sélectionner sa photo */}
      {!readOnly && (
        <input
          type="file"
          accept="image/jpeg, image/png, image/tiff, image/webp"
          ref={inputFileRef}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onChange={handleImageUpload}
          style={{ visibility: "collapse" }}
        />
      )}
      <Grid container spacing={smUp ? 3 : 2} size={12}>
        {
          /* petit trick pour avoir un tableau [0,1,2...maximumPhotos - 1 en Typescript */
          Array.from(Array(maximumPhotos).keys()).map((index: number) => {
            if (readOnly && images[index]?.base64String == null) {
              return "";
            }
            return (
              <Grid key={index} size={{ xs: 6, sm: 4 }}>
                <PhotoInput
                  readOnly={readOnly}
                  isLoading={images[index]?.isLoading}
                  base64String={images[index]?.base64String}
                  deleteImage={(): void => {
                    deleteImage(index);
                  }}
                  selectImage={selectImage}
                />
              </Grid>
            );
          })
        }
        {(readOnly ?? false) && images?.length <= 0 && (
          <Grid size={12}>
            <p style={{ margin: 0 }}>Aucune photo n'a été ajoutée à cette déclaration.</p>
          </Grid>
        )}
      </Grid>
    </>
  );
}

export default PhotosInput;
