import { useEffect } from "react";
import type { ReactElement } from "react";
import { Paper, Zoom, Fade } from "@mui/material";

type PhotoInputProps = {
  src: string;
  open: boolean;
  setOpen: (o: boolean) => void;
  altText?: string;
};

function ImageZoomModal({ src, open, setOpen, altText = "image" }: PhotoInputProps): ReactElement {
  useEffect(() => {
    window.addEventListener("scroll", handleClose);
    window.addEventListener("keydown", closeOnEscape);

    return () => {
      /** return a cleanup function to unregister our function
       * since its gonna run multiple times */
      window.removeEventListener("scroll", handleClose);
      window.removeEventListener("keydown", closeOnEscape);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClose(): void {
    setOpen(false);
  }

  function closeOnEscape(event: KeyboardEvent): void {
    if (event.key === "Escape") {
      handleClose();
    }
  }

  const allZero = {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  };

  return (
    <Fade in={open} mountOnEnter unmountOnExit>
      <Paper
        onClick={handleClose}
        sx={{
          position: "fixed",
          ...allZero,
          height: "100%",
          width: "100%",
          backgroundColor: "rgba(255, 255, 255, 75%)",
          cursor: "zoom-out",
          zIndex: "12000",
        }}>
        <Zoom in={open} easing="ease-out">
          <img
            alt={altText}
            src={src}
            style={{
              display: "block",
              width: "100vw",
              height: "100vh",
              objectFit: "contain",
            }}
          />
        </Zoom>
      </Paper>
    </Fade>
  );
}

export default ImageZoomModal;
