import { utils, writeFileXLSX } from "xlsx";
import type { CellObject, WorkBook, WorkSheet } from "xlsx";
import type Commune from "models/Commune";
import type User from "models/User";
import agentService from "services/agentService";
import type { Declaration } from "models/Declaration";
import siegeLesions from "data/siegeLesions";
import type { Securite } from "models/Securite";
import declarationService from "./declarationService";
import type Lesion from "models/Lesion";
import TypeAccident from "constants/TypeAccident";
import { dateUtil } from "@sdeapps/react-core";
import risquesSecurites from "data/risquesSecurites";
import { routesConfig } from "app-config";
import graphService from "./graphService";

async function exportDeclarations(
  declarations: Array<Declaration>,
  communes: Array<Commune>,
  agents: Array<User>,
  filename: string | null = null
): Promise<void> {
  const header = [
    "Id",
    "",
    "Date accident",
    "Année",
    "Catégorie",
    "Nom agent",
    "Fonction",
    "Description Circonstances",
    "Risques/Conséquences",
    "Nature du risque",
    "Siège des lésions",
    "Nature des lésions",
    "",
    "",
    "Proposition de solution(s)",
    "Territoire de l'accident",
    "Commune",
    "Rue",
    "Type d'ouvrage",
    "Service d'affectation",
    "",
    "",
    "",
    "Déclarant",
    "Manager Déclarant",
    "Manager Victime",
    "confidentielle",
    "Photos",
  ];

  const data: Array<Array<string>> = [header];

  await AddDeclarations(data, declarations, agents, communes);

  const workSheet: WorkSheet = utils.aoa_to_sheet(data);

  transformColumnIntoHyperlinks(data, workSheet, "X", 1);

  const workbook: WorkBook = utils.book_new();
  utils.book_append_sheet(workbook, workSheet, "Liste des déclarations");

  writeFileXLSX(workbook, `${filename ?? "declarations"}.xlsx`);
}

function exportRegistreAccidentsBenins(
  declarations: Array<Securite>,
  communes: Array<Commune>,
  agents: Array<User>,
  filename: string | null = null
): void {
  const headers = [
    [
      "N° d'ordre",
      "Date d'inscription dans le registre",
      "Nom, prénom, Matricule interne de la victime",
      "ACCIDENT",
      "",
      "",
      "",
      "",
      "",
      "",
      "Signature du donneur de soins",
      "Signature de la victime",
      "OBSERVATIONS (Date de la DAT éventuellement)",
    ],
    [
      "",
      "",
      "",
      "Date et heure",
      "Lieu",
      "Circonstances détaillées (indiquer, le cas échéant l'appareil, la machine ou le moyen de locomotion utilisé)",
      "Siège des lésions (indiquer s'il y a lieu droite ou gauche)",
      "Nature des lésions",
      "Nom et adresse des témoins ou de la première personne avisée",
      "Nom et adresse des tiers impliqués extérieurs à l'établissement",
      "",
      "",
      "",
    ],
  ];

  const data: Array<Array<string>> = [...headers];

  AddAccidentsBeninsData(data, declarations, agents, communes);

  const workSheet: WorkSheet = utils.aoa_to_sheet(data);
  const workbook: WorkBook = utils.book_new();
  utils.book_append_sheet(workbook, workSheet, "Registre des accidents benins");

  // Fusion des cellules du header
  workSheet["!merges"] = [
    { s: { c: 0, r: 0 }, e: { c: 0, r: 1 } },
    { s: { c: 1, r: 0 }, e: { c: 1, r: 1 } },
    { s: { c: 2, r: 0 }, e: { c: 2, r: 1 } },
    { s: { c: 3, r: 0 }, e: { c: 9, r: 0 } },
    { s: { c: 10, r: 0 }, e: { c: 10, r: 1 } },
    { s: { c: 11, r: 0 }, e: { c: 11, r: 1 } },
    { s: { c: 12, r: 0 }, e: { c: 12, r: 1 } },
  ];

  writeFileXLSX(workbook, `${filename ?? "registre"}.xlsx`);
}

async function AddDeclarations(
  data: Array<Array<string>>,
  declarations: Array<Declaration>,
  agents: Array<User>,
  communes: Array<Commune>
): Promise<void> {
  declarations.sort((a: Declaration, b: Declaration) => {
    return a.displayNumber - b.displayNumber;
  });

  for (const dec of declarations) {
    const sec = dec as Securite;
    const victim: User | undefined =
      agentService.getById(agents, dec.victimeId) ??
      declarationService.getSnapshotVictim(dec) ??
      agentService.getById(agents, dec.creatorId);

    const creator = agentService.getById(agents, dec.creatorId) ?? victim;
    const creatorManager =
      (await graphService.getManagerByAgent(dec.creatorId)) ??
      (await graphService.getManagerByAgent(dec.victimeId));
    const victimManager = await graphService.getManagerByAgent(dec.victimeId);

    let risque: string | null = null;
    risquesSecurites.forEach((r) => {
      if (sec.risque === r.label) risque = `${r.index} - ${r.label}`;
    });
    if (dec.id == null) {
      console.warn("dec.id is null in ", dec);
      return;
    }

    data.push([
      dec.displayNumber.toString(),
      "",
      dateUtil.format(dec.dateEtHeure, "dd/MM/yyyy"),
      dateUtil.getYear(dec.dateEtHeure),
      declarationService.getTypeDeclarationLabelFromDeclaration(dec),
      victim?.displayName ?? "",
      victim?.jobTitle ?? "",
      `${dec.precisions ?? ""} / ${dec.activiteEnCours ?? ""} / ${dec.commentaire ?? ""}`,
      sec?.consequences ?? "",
      risque ?? "",
      dec?.lesions?.map((lesion) => writeLesion(lesion))?.join(" / ") ?? "",
      dec?.lesions?.map((lesion) => lesion.typeLesion)?.join(" / ") ?? "",
      "",
      "",
      sec?.proposition ?? "",
      dec.territoire,
      communes.find((v: Commune) => v.code === dec.communeInsee)?.nom ?? dec.communeName,
      dec.rue,
      dec.ouvrageType === "NON" ? "" : dec.ouvrageType,
      victim?.department ?? "",
      "",
      "",
      "",
      creator?.displayName ?? "",
      creatorManager?.displayName ?? "",
      victimManager?.displayName ?? "",
      dec.isConfidentiel ? "OUI" : "NON",
      (dec.photos?.length ?? 0) > 0 ? getFullUrlForDeclaration(dec) : "",
    ]);
  }
}

function getFullUrlForDeclaration(dec: Declaration): string {
  if (dec.id == null) {
    throw Error("dec.id is null");
  }
  return `${window.location.origin}${
    // eslint-disable-next-line @typescript-eslint/no-base-to-string
    declarationService.isAccident(dec)
      ? routesConfig.accidentPhotos.getParameterPath(dec.id)
      : routesConfig.securitePhotos.getParameterPath(dec.id)
  }`;
}

function AddAccidentsBeninsData(
  data: Array<Array<string>>,
  accidentsBenins: Array<Securite>,
  agents: Array<User>,
  communes: Array<Commune>
): void {
  accidentsBenins.sort((a: Securite, b: Securite) => {
    return (a?.accidentBeninDisplayNumber ?? 0) - (b?.accidentBeninDisplayNumber ?? 0);
  });

  accidentsBenins.forEach((accidentBenin) => {
    if (accidentBenin.accidentBeninSauveteurId == null) {
      console.warn("accidentBenin.accidentBeninSauveteurId is null in ", accidentBenin);
      return;
    }
    if (accidentBenin.accidentBeninDisplayNumber == null) {
      console.warn("accidentBenin.accidentBeninDisplayNumber is null in ", accidentBenin);
      return;
    }

    const victim: User | undefined =
      agentService.getById(agents, accidentBenin.victimeId) ??
      declarationService.getSnapshotVictim(accidentBenin);
    const sauveteur: User | undefined = agentService.getById(
      agents,
      accidentBenin.accidentBeninSauveteurId
    );

    data.push([
      accidentBenin.accidentBeninDisplayNumber.toString(),
      dateUtil.format(accidentBenin.creationDate, "dd/MM/yyyy"),
      `${victim?.displayName ?? ""} ${victim?.employeeId ?? ""}`,
      dateUtil.format(accidentBenin.dateEtHeure, "dd/MM/yyyy HH:mm"),
      `${accidentBenin.rue ?? ""} ${
        communes.find((v: Commune) => v.code === accidentBenin.communeInsee)?.nom ??
        accidentBenin.communeName
      }`,
      `${accidentBenin.precisions ?? ""} / ${accidentBenin.activiteEnCours ?? ""}`,
      accidentBenin?.lesions?.map((lesion) => writeLesion(lesion))?.join(" / ") ?? "",
      accidentBenin?.lesions?.map((lesion) => lesion.typeLesion)?.join(" / ") ?? "",
      accidentBenin?.temoin ?? "",
      accidentBenin?.tiers ?? "",
      getSSTSignature(accidentBenin, sauveteur),
      getVictimSignature(accidentBenin, victim),
      getObservations(accidentBenin),
    ]);
  });
}

function getVictimSignature(accidentBenin: Securite, victim?: User): string {
  if (accidentBenin.accidentBeninConfirmationVictime) {
    return victim?.displayName ?? accidentBenin.victimeId;
  }
  return "";
}

function getSSTSignature(accidentBenin: Securite, sauveteur?: User): string {
  if (accidentBenin.accidentBeninConfirmationSauveteur) {
    return sauveteur?.displayName ?? accidentBenin.accidentBeninSauveteurId ?? "";
  }
  return "";
}

function getObservations(accidentBenin: Securite): string {
  if (
    (accidentBenin.isRequalificationOrigin ?? false) &&
    (accidentBenin.requalification?.type === TypeAccident.TRAJET ||
      accidentBenin.requalification?.type === TypeAccident.TRAVAIL)
  ) {
    return `Date de la DAT : ${dateUtil.format(
      accidentBenin.requalification.creationDate,
      "dd/MM/yyyy HH:mm"
    )}. `;
  }

  return "";
}

function writeLesion(lesion: Lesion): string {
  let s =
    siegeLesions.find((siege) => siege.key === lesion.siegeLesion)?.value ?? lesion.siegeLesion;
  if (lesion.gauche) {
    s += " Gauche";
    if (lesion.droite) {
      s += " et";
    }
  }
  if (lesion.droite) {
    s += " Droite";
  }

  return s;
}

function transformColumnIntoHyperlinks(
  data: Array<Array<string>>,
  workSheet: WorkSheet,
  colName: string,
  headerRowNumber: number = 0
): void {
  data.forEach((_v, i) => {
    const cell: CellObject | undefined = workSheet[`${colName}${i + 1 + headerRowNumber}`];
    if (cell?.v != null && cell.v !== "") {
      workSheet[`${colName}${i + 2}`].l = { Target: cell.v };
    }
  });
}

const excelService = {
  exportDeclarations,
  exportRegistreAccidentsBenins,
};

export default excelService;
