import { useState } from "react";
import type { ReactElement } from "react";
import { Button, Chip, Grid2 as Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import LesionsModal from "components/inputs/lesions/LesionsModal";
import type Lesion from "models/Lesion";
import siegeLesions from "data/siegeLesions";

interface LesionSectionProps {
  value: Array<Lesion>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: Array<any>) => void;
  readOnly?: boolean;
}

function LesionSection({ value, onChange, readOnly = false }: LesionSectionProps): ReactElement {
  const [open, setOpen] = useState<boolean>(false);

  function remove(index: number): void {
    const a = [...value];
    a.splice(index, 1);
    onChange([...a]);
  }

  function addLesion(lesion: Lesion): void {
    onChange([...value, lesion]);
  }

  function getLabel(lesion: Lesion): string {
    return `${lesion.typeLesion}, ${
      siegeLesions.find((siege) => siege.key === lesion.siegeLesion)?.value
    }${lesion.gauche || lesion.droite ? ", " : ""}${lesion.gauche ? "Gauche" : ""}${
      lesion.gauche && lesion.droite ? " et " : ""
    }${lesion.droite ? "Droite" : ""}`;
  }

  function getOnDelete(index: number): object {
    if (readOnly) {
      return {};
    } else {
      return {
        onDelete: () => {
          remove(index);
        },
      };
    }
  }

  return (
    <Grid container size={12} spacing={2}>
      <Grid size={12}>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(true);
          }}
          endIcon={<AddIcon />}
          disabled={readOnly}>
          AJOUTER UNE LÉSION
        </Button>
      </Grid>
      {value?.map((lesion: Lesion, index: number) => (
        <Grid key={getLabel(lesion)} size="auto">
          <Chip label={getLabel(lesion)} {...getOnDelete(index)} variant="outlined" />
        </Grid>
      ))}
      <LesionsModal
        addLesion={addLesion}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      />
    </Grid>
  );
}

export default LesionSection;
