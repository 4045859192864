import React, { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import App from "App";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";
import CssBaseline from "@mui/material/CssBaseline";
import UpdateSnackbar from "components/utils/PopupHandler/UpdateSnackbar";

function openRefreshSnackBar(skipWaiting: () => void): void {
  ReactDOM.createRoot(document.getElementById("refresh-snackbar") as ReactDOM.Container).render(
    <React.StrictMode>
      <UpdateSnackbar skipWaiting={skipWaiting} />
    </React.StrictMode>
  );
}

function setSkipWaitingUp(registration: ServiceWorkerRegistration): void {
  const waitingServiceWorker = registration.waiting;

  if (waitingServiceWorker != null) {
    waitingServiceWorker.addEventListener("statechange", (event) => {
      const eTarget: ServiceWorker | null = event?.target as ServiceWorker;
      if (eTarget?.state === "activated") {
        window.location.reload();
      }
    });

    openRefreshSnackBar(() => {
      registration?.waiting?.postMessage({ type: "SKIP_WAITING" });
    });
  }
}

/** Inscription du Service Worker */
serviceWorkerRegistration.register({
  onUpdate: setSkipWaitingUp,
  onWaiting: setSkipWaitingUp,
});

ReactDOM.createRoot(document.getElementById("root") as ReactDOM.Container).render(
  <StrictMode>
    <CssBaseline />
    <App />
  </StrictMode>
);
