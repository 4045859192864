import type { PropsWithChildren, ReactElement } from "react";
import { Container, Grid2 as Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import BackButton from "components/buttons/BackButton";
import DividerTitle from "components/utils/DividerTitle";

function Em({ children }: PropsWithChildren): ReactElement {
  return <span style={{ fontWeight: "bold" }}>{children}</span>;
}

function EnSavoirPlusPage(): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container fixed>
      <Typography color="primary" variant={isSmallScreen ? "h4" : "h3"}>
        <BackButton />
        Pour mieux comprendre
      </Typography>
      <Grid container spacing={3}>
        <Grid size="grow">
          <DividerTitle>
            <Typography sx={{ marginY: 3 }} variant={isSmallScreen ? "h5" : "h4"} color="primary">
              Qu'est-ce que je peux déclarer ?
            </Typography>
          </DividerTitle>
        </Grid>
        <Grid container size={12} spacing={3}>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography>
              <Em>Situation dangereuse</Em> : C'est une situation susceptible de provoquer une
              blessure ou dans certaines conditions, une maladie professionnelle, consécutive à des
              efforts physiques, des écrasements, des chocs, des gestes répétitifs, des mauvaises
              postures... du fait d'un poste de travail inadapté.
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography>
              <Em>Presqu'accident</Em> : Un presqu'accident est «&nbsp;un événement indésirable
              n'induisant aucun traumatisme ni aucune pathologie, mais ayant le potentiel de le
              faire cf : ISO 45001&nbsp;» par conséquent cela évoque la potentialité d'apparition
              d'un accident du travail.
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography>
              <Em>Accident bénin</Em> : L'accident de travail bénin désigne une situation : vécue
              par un salarié sur le lieu d'exercice de son travail, notifié dans son contrat ;
              n'ayant entrainé aucune interruption de travail, même temporaire ; ayant nécessité des
              soins médicaux légers dispensés immédiatement et sur place par un secouriste du
              travail.
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography>
              <Em>Accident de trajet</Em> : L'accident de trajet est celui qui survient au salarié
              lors du parcours aller-retour qu'il effectue entre sa résidence et son lieu de
              travail, ou entre le lieu de travail et le lieu où il prend ses repas, pendant les
              temps où il emprunte habituellement ces trajets.
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Typography>
              <Em>Accident de travail</Em> : L'accident survient, en principe, aux temps et lieu de
              travail, sous l'autorité de l'employeur. L'accident doit survenir au temps de travail
              pendant les périodes d'exécution du travail, mais aussi pendant les temps de présence
              du salarié en relation avec l'activité professionnelle (temps de pause, passage dans
              les parkings, pause déjeuner dans les locaux de l'entreprise, passage aux vestiaires,
              rangement des outils, etc.), et au lieu de travail : au poste de travail du salarié,
              mais aussi, plus largement, dans l'ensemble des locaux de l'entreprise (vestiaires,
              cantine, parkings). Le lieu de travail comprend en effet le lieu de travail proprement
              dit (atelier, chantier, bureau, etc.), mais également l'enceinte de l'entreprise et
              ses dépendances (vestiaires, escaliers, voies d'accès et de sortie de 'entreprise,
              parkings, cantine, restaurant interentreprises, etc.). Si l'accident survient pendant
              que le salarié réalise une tâche relevant de ses fonctions pendant son temps de
              travail, cet accident est automatiquement présumé être un accident du travail.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default EnSavoirPlusPage;
