import { useMediaQuery, Grid2 as Grid, Typography, Container, useTheme } from "@mui/material";
import DangerGraveForm from "components/sections/Forms/DangerGraveForm";
import type { ReactElement } from "react";

function DangerGravePage(): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Container maxWidth="md">
      <Grid container size={12} spacing={2}>
        <Grid size={12}>
          <Typography variant={isSmallScreen ? "h5" : "h4"}>
            Nouveau danger grave et imminent
          </Typography>
        </Grid>
        <Grid size={12}>
          <DangerGraveForm />
        </Grid>
      </Grid>
    </Container>
  );
}

export default DangerGravePage;
