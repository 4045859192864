import type { ReactElement, ReactNode } from "react";
import {
  Button,
  Container,
  Grid2 as Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import HelpIcon from "@mui/icons-material/Help";
import { routesConfig } from "app-config";

function AddButton({ children, linkTo }: { children?: ReactNode; linkTo: string }): ReactElement {
  return (
    <Button variant="contained" href={linkTo} startIcon={<AddIcon />}>
      {children}
    </Button>
  );
}

const descriptionTextVariant = "body1";
const declarationTypes = [
  {
    linkTo: routesConfig.situationDangereuse.path,
    title: "Situation dangereuse",
    description:
      "Situation susceptible de causer un dommage à l'intégrité mentale ou physique du salarié.",
  },
  {
    linkTo: routesConfig.presquAccident.path,
    title: "Presqu'accident",
    description:
      "Un évènement indésirable n'induisant aucun traumatisme ni aucune pathologie, mais ayant le potentiel de le faire.",
  },
  {
    linkTo: routesConfig.accidentBenin.path,
    title: "Accident bénin",
    description:
      "Tout accident n'ayant entraîné ni un arrêt de travail ni de soins médicaux effectués par un professionnel de santé.",
  },
  {
    linkTo: routesConfig.accidentTravail.path,
    title: "Accident de Travail",
    description:
      "Tout accident survenant pendant et sur le lieu de travail, y compris la pause déjeuner, entraînant un arrêt de travail et/ou une consultation/soins médicaux.",
  },
  {
    linkTo: routesConfig.accidentTrajet.path,
    title: "Accident de Trajet",
    description: (
      <>
        Tout accident survenant pendant le trajet aller-retour entre:
        <ul>
          <li>le lieu de résidence et le lieu de travail.</li>
          <li>le lieu de travail et le lieu où le salarié prend habituellement ses repas.</li>
        </ul>
      </>
    ),
  },
  {
    linkTo: routesConfig.dangerGrave.path,
    title: "Danger grave et imminent",
    description: (
      <>
        Cette déclaration doit être faite{" "}
        <strong>uniquement si les deux conditions suivantes sont cumulées</strong>: elle vous permet
        de faire valoir votre droit de retrait.
        <ul>
          <li>
            <Typography component="span" sx={{ fontWeight: "bold", textDecoration: "underline" }}>
              Un danger grave
            </Typography>{" "}
            est considéré comme une menace directe pour la vie, l&apos;intégrité physique ou la
            santé d&apos;un agent, susceptible de produire un accident ou une maladie entraînant la
            mort ou paraissant devoir entraîner une incapacité permanente ou temporaire prolongée.
          </li>
          <br />
          <li>
            <Typography component="span" sx={{ fontWeight: "bold", textDecoration: "underline" }}>
              Un danger imminent
            </Typography>{" "}
            est une notion qui implique la survenance quasi immédiate d&apos;un danger sans
            qu&apos;il soit possible de l&apos;éviter en mettant en place une mesure de prévention.
          </li>
        </ul>
      </>
    ),
  },
];

function TypeSelection(): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Container maxWidth="md">
      <Grid container size={12} spacing={3}>
        <Grid size={12} sx={{ mb: 2 }}>
          <Typography variant={isSmallScreen ? "h4" : "h3"} component="div">
            Nouvelle Déclaration
          </Typography>
        </Grid>
        <Grid container spacing={4} size={12}>
          {declarationTypes.map((v) => {
            return (
              <Grid
                container
                size={{ xs: 12, md: 6 }}
                spacing={2}
                key={v.title}
                sx={{ alignContent: "flex-start" }}>
                <Grid size={12}>
                  <AddButton linkTo={v.linkTo}>{v.title}</AddButton>
                </Grid>
                <Grid size={12}>
                  <Typography variant={descriptionTextVariant} component="div">
                    {v.description}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>

        <Grid size={12}>
          <Button
            variant="text"
            href={routesConfig.enSavoirPlus.path}
            color="info"
            startIcon={<HelpIcon />}>
            En savoir plus
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}

export default TypeSelection;
