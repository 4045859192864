import { apiConfig, config } from "app-config";
import authService from "./authService";
import cacheService from "./cacheService";
import errorHandlingService from "./errorHandlingService";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function getJsonValue(res: Response): Promise<any> {
  return (await res.json())?.value;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function getJsonData(res: Response): Promise<any> {
  return (await res.json())?.data;
}

interface FetchWithCacheFallbackOptions {
  checkResValidity: boolean;
}

async function fetchWithCacheFallback(
  url: string,
  fetchOptions: RequestInit,
  cacheName: string,
  options: FetchWithCacheFallbackOptions = { checkResValidity: true }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> {
  return fetch(url, fetchOptions)
    .then(
      options.checkResValidity
        ? errorHandlingService.defaultAndDefined
        : errorHandlingService.defaultErrorHandling
    )
    .catch(async () => {
      return cacheService.match(url, cacheName);
    });
}

/**
 * Génère les headers HTTP nécessaires pour requêter nos APIs via l'APIM
 * en fonction des scopes demandés
 * @returns Les Headers
 */
async function createHeaders(scopes: Array<string>): Promise<HeadersInit> {
  const accessToken = await authService.getAccessToken(scopes);

  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${accessToken}`,
    "Ocp-Apim-Subscription-Key": apiConfig.apimSubscriptionKey,
    "Sdeapps-Version": config.appVersion,
    "Sdeapps-Application": config.appName,
  };
}

const networkService = {
  getJsonValue,
  getJsonData,
  fetchWithCacheFallback,
  createHeaders,
};
export default networkService;
