import { Skeleton, Typography } from "@mui/material";
import TypeSecurite from "constants/TypeSecurite";
import type { Declaration } from "models/Declaration";
import { useData } from "providers/DataProvider";
import agentService from "services/agentService";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import type { ReactElement } from "react";

type ConfirmationDetailsSubtitleProps = {
  isLoading: boolean;
  declaration: Declaration;
  isSmallScreen?: boolean;
};

function ConfirmationDetailsSubtitle({
  isLoading,
  declaration,
  isSmallScreen = false,
}: ConfirmationDetailsSubtitleProps): ReactElement {
  const { agents, isLoading: isDataLoading } = useData();
  const emStyle = { fontStyle: "normal", fontWeight: 600 };

  if (isLoading || isDataLoading) {
    return <Skeleton width="75%" />;
  }

  if (declaration.type !== TypeSecurite.ACCIDENT_BENIN) {
    return <></>;
  }

  function displayConfirmation(
    confirmation: boolean,
    confirmedProperty: string,
    confirmerId: string | null
  ): ReactElement {
    return (
      <Typography
        variant={isSmallScreen ? "subtitle2" : "subtitle1"}
        gutterBottom
        sx={{
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          justifyItems: "center",
        }}>
        {confirmation ? (
          <CheckIcon
            fontSize="small"
            color="success"
            style={{ verticalAlign: "text-bottom" }}
            sx={{ mr: 1 }}
          />
        ) : (
          <CloseIcon
            fontSize="small"
            color="error"
            style={{ verticalAlign: "text-bottom" }}
            sx={{ mr: 1 }}
          />
        )}
        <em style={emStyle}>
          {agentService.getDisplayNameById(agents, confirmerId ?? "") ?? confirmerId}
        </em>
        {confirmation ? " a " : " n'a pas "}confirmé être {confirmedProperty}.
      </Typography>
    );
  }

  return (
    <>
      {displayConfirmation(
        declaration.accidentBeninConfirmationSauveteur,
        "le SST",
        declaration?.accidentBeninSauveteurId
      )}
      {displayConfirmation(
        declaration.accidentBeninConfirmationVictime,
        "la victime",
        declaration?.victimeId
      )}
    </>
  );
}

export default ConfirmationDetailsSubtitle;
