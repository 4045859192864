import { useState } from "react";
import type { ReactElement } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
} from "@mui/material";
import { routesConfig } from "app-config";
import DeclarationTypeSelectionCheckboxes from "components/inputs/DeclarationTypeSelectionCheckboxes";
import TypeAccident from "constants/TypeAccident";
import TypeSecurite from "constants/TypeSecurite";
import type { Declaration } from "models/Declaration";
import { useNavigate } from "react-router-dom";
import declarationService from "services/declarationService";

function cleanUp(dirtyDeclaration: Declaration): Declaration {
  const declaration = dirtyDeclaration;
  delete declaration.isRequalificationOrigin;
  declaration.requalification = { ...declaration };
  declaration.requalificationType = declarationService.isAccident(declaration)
    ? "accident"
    : "securite";
  declaration.id = null;

  return declaration;
}

function getUrlByType(type: string): string {
  switch (type) {
    case TypeAccident.TRAJET:
      return routesConfig.accidentTrajet.path;
    case TypeAccident.TRAVAIL:
      return routesConfig.accidentTravail.path;
    case TypeSecurite.ACCIDENT_BENIN:
      return routesConfig.accidentBenin.path;
    case TypeSecurite.PRESQU_ACCIDENT:
      return routesConfig.presquAccident.path;
    case TypeSecurite.SITUATION_DANGEREUSE:
      return routesConfig.situationDangereuse.path;
    default:
      return "";
  }
}

type RequalificationModalProps = {
  open: boolean;
  handleClose: () => void;
  declaration: Declaration;
};

function RequalificationModal({
  open,
  handleClose,
  declaration,
}: RequalificationModalProps): ReactElement {
  const [selectedTypeArray, setSelectedTypeArray] = useState<Array<TypeAccident | TypeSecurite>>(
    []
  );
  const navigate = useNavigate();

  function requalify(selectedTypeArray: Array<TypeAccident | TypeSecurite>): void {
    if (selectedTypeArray.length < 1) return;

    const selectedType = selectedTypeArray[0];
    const dec = cleanUp(declaration);
    dec.type = selectedType;

    navigate(getUrlByType(selectedType), {
      state: { requalifyingDeclaration: dec },
    });
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Requalifier une déclaration</DialogTitle>
      <DialogContent>
        <Grid container size={12} spacing={3} sx={{ maxWidth: "md" }}>
          <Grid container size={12}>
            <DeclarationTypeSelectionCheckboxes
              onChange={setSelectedTypeArray}
              label="Requalifier en *"
              disabledChoice={declaration.type}
              unique
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button color="error" onClick={handleClose}>
          Annuler
        </Button>
        <Button
          onClick={() => {
            requalify(selectedTypeArray);
          }}
          variant="contained"
          disabled={selectedTypeArray.length < 1}>
          Requalifier
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default RequalificationModal;
