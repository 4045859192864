import { Grid2 as Grid, Typography } from "@mui/material";
import DividerTitle from "components/utils/DividerTitle";
import InfoBulleSwitch from "components/utils/InfoBulles/InfoBulleSwitch";
import type { ReactElement } from "react";

type FormSectionTitleProps = {
  title: string;
  infoKey?: string;
};

function FormSectionTitle({ title, infoKey }: FormSectionTitleProps): ReactElement {
  return (
    <Grid container size={12} sx={{ alignItems: "center" }}>
      <Grid size="grow">
        <DividerTitle>
          <Typography variant="h5">{title}</Typography>
        </DividerTitle>
      </Grid>
      {infoKey != null && infoKey !== "" && (
        <Grid size="auto">
          <InfoBulleSwitch infoKey={infoKey} />
        </Grid>
      )}
    </Grid>
  );
}

export default FormSectionTitle;
