import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import { FormControlLabel, FormGroup, Switch, type SxProps, useTheme } from "@mui/material";
import { useInfo } from "providers/InfoBullesProvider";
import { getHelpIconInfoSwitchStyles } from "./styles";

interface InfoSwitchProps {
  infoKey: string;
  sx?: SxProps;
}

/**
 * Permet d'afficher/cacher le/les InfoBulle associées (avec la même infoKey).
 * Doit être à l'intérieur d'un InfoBulleProvider.
 * @param {string} infoKey clé permettant de faire le lien entre ce composant et les InfoBulle associées
 */
function InfoBulleSwitch({ infoKey, sx }: InfoSwitchProps): ReactElement {
  const [checked, setChecked] = useState(false);
  const {
    infoSwitches,
    getInfoBulleSwitch: getInfoSwitch,
    setInfoBulleSwitch: setInfoSwitch,
  } = useInfo();

  const theme = useTheme();
  const helpIconInfoSwitchStyles = getHelpIconInfoSwitchStyles(theme);

  useEffect(() => {
    setChecked(getInfoSwitch(infoKey));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoKey, infoSwitches]);

  return (
    <FormGroup sx={sx} row>
      <FormControlLabel
        control={
          <Switch
            color="info"
            sx={helpIconInfoSwitchStyles}
            checked={checked}
            onChange={(_e, checked) => {
              setInfoSwitch(infoKey, checked);
            }}
          />
        }
        label="Aide"
        labelPlacement="start"
      />
    </FormGroup>
  );
}

export default InfoBulleSwitch;
