import { useEffect, useRef, useState } from "react";
import type { ChangeEvent, ReactElement } from "react";
import {
  type ButtonBaseActions,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid2 as Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import TypeAccident from "constants/TypeAccident";
import TypeSecurite from "constants/TypeSecurite";
import type { RefCallBack } from "react-hook-form";

const emptyDeclarationTypes = [
  {
    value: TypeAccident.TRAJET,
    name: "Accident de Trajet",
    checked: false,
  },
  {
    value: TypeAccident.TRAVAIL,
    name: "Accident de Travail",
    checked: false,
  },
  {
    value: TypeSecurite.ACCIDENT_BENIN,
    name: "Accident Bénin",
    checked: false,
  },
  {
    value: TypeSecurite.PRESQU_ACCIDENT,
    name: "Presqu'Accident",
    checked: false,
  },
  {
    value: TypeSecurite.SITUATION_DANGEREUSE,
    name: "Situation dangereuse",
    checked: false,
  },
];

interface DeclarationTypeSelectionCheckboxesProps {
  onChange: (value: Array<TypeAccident | TypeSecurite>) => void;
  label?: string;
  error?: boolean;
  unique?: boolean;
  helperText?: string;
  inputRef?: RefCallBack;
  disabledChoice?: TypeAccident | TypeSecurite;
}

function DeclarationTypeSelectionCheckboxes({
  onChange,
  label = "",
  error = false,
  unique = false,
  helperText = "",
  inputRef,
  disabledChoice,
}: DeclarationTypeSelectionCheckboxesProps): ReactElement {
  const [declarationTypes, setDeclarationTypes] = useState(emptyDeclarationTypes);
  const action = useRef<ButtonBaseActions>(null);

  function handleCheckboxChange(e: ChangeEvent<HTMLInputElement>, index: number): void {
    const tempDeclarationTypes = [...declarationTypes];
    tempDeclarationTypes[index].checked = e.target.checked;
    setDeclarationTypes(tempDeclarationTypes);
  }

  function handleRadioChange(e: ChangeEvent<HTMLInputElement>): void {
    onChange([e.target.value as TypeAccident | TypeSecurite]);
  }

  function passRefToFirst(index: number): {
    inputRef?: RefCallBack;
    action?: React.RefObject<ButtonBaseActions>;
    onFocus?: () => void;
  } {
    // on passe la ref à la première checkbox, c'est elle qui sera focusée
    if (index === 0) {
      return {
        inputRef,
        action,
        // eslint-disable-next-line @typescript-eslint/unbound-method
        onFocus: action?.current?.focusVisible,
      };
    } else {
      return {};
    }
  }

  useEffect(() => {
    const selectedTypes: Array<TypeAccident | TypeSecurite> = [];
    declarationTypes.forEach((element) => {
      if (element.checked) {
        selectedTypes.push(element.value);
      }
    });
    onChange(selectedTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [declarationTypes]);

  return (
    <FormControl fullWidth error={error}>
      <Grid container size={12}>
        <Grid size={12} sx={{ textAlign: "left" }}>
          <FormLabel>{label !== "" ? label : "Type de déclaration"}</FormLabel>
        </Grid>
        {unique ? (
          <RadioGroup
            onChange={(e) => {
              handleRadioChange(e);
            }}>
            <Grid container size={12}>
              {declarationTypes.map((t, index) => {
                return (
                  <Grid key={t.value} size={{ xs: 12, sm: 6 }}>
                    <FormControlLabel
                      value={t.value}
                      control={<Radio {...passRefToFirst(index)} />}
                      label={t.name}
                      disabled={t.value === disabledChoice}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </RadioGroup>
        ) : (
          declarationTypes.map((t, index) => {
            return (
              <Grid key={t.value} size={{ xs: 12, sm: 6 }}>
                <FormControlLabel
                  value={t.value}
                  control={
                    <Checkbox
                      {...passRefToFirst(index)}
                      checked={t.checked}
                      onChange={(e) => {
                        handleCheckboxChange(e, index);
                      }}
                      disabled={t.value === disabledChoice}
                    />
                  }
                  label={t.name}
                  labelPlacement="end"
                />
              </Grid>
            );
          })
        )}
        <FormHelperText>{helperText}</FormHelperText>
      </Grid>
    </FormControl>
  );
}

export default DeclarationTypeSelectionCheckboxes;
