import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography } from "@mui/material";
import TypeSecurite from "constants/TypeSecurite";
import type { Declaration } from "models/Declaration";
import ConfirmationDetailsSubtitle from "./ConfirmationDetailsSubtitle";
import CreationDetailsSubtitle from "./CreationDetailsSubtitle";
import RequalificationDetailsSubtitle from "./RequalificationDetailsSubtitle";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreOutlinedIcon from "@mui/icons-material/MoreOutlined";
import type { ReactElement } from "react";
import { useDeclarationCreationDetails } from "./useDeclarationCreationDetails";

type DeclarationDetailsProps = {
  isNew: boolean;
  isLoading: boolean;
  declaration?: Declaration;
  isSmallScreen?: boolean;
  isRequalifying?: boolean;
};

function DeclarationDetails({
  isNew,
  isLoading,
  declaration,
  isSmallScreen = false,
  isRequalifying = false,
}: DeclarationDetailsProps): ReactElement {
  const { isDeclarationOrigin, creationDetails, requalDetails } =
    useDeclarationCreationDetails(declaration);

  if (
    isNew &&
    declaration?.requalification == null &&
    declaration?.type !== TypeSecurite.ACCIDENT_BENIN
  ) {
    return <></>;
  }

  return (
    <Accordion
      disableGutters={true}
      variant="outlined"
      sx={{
        transition: "background .5s ease",
        "	.MuiDivider-root": { display: "none" },
        "&.Mui-expanded": {
          background: "#e3f2fd",
          "	.MuiDivider-root": { display: "initial" },
        },
      }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" sx={{ alignItems: "center" }}>
          <MoreOutlinedIcon
            fontSize="small"
            color="info"
            sx={{
              verticalAlign: "sub",
              transform: "scaleX(-1)",
            }}
          />{" "}
          Détails
        </Typography>
        <Divider
          variant="middle"
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            left: 0,
          }}
        />
      </AccordionSummary>
      <AccordionDetails>
        {!isNew && (
          <CreationDetailsSubtitle
            isSmallScreen={isSmallScreen}
            isLoading={isLoading}
            creationDetails={creationDetails}
          />
        )}
        {declaration?.requalification != null && (
          <RequalificationDetailsSubtitle
            isSmallScreen={isSmallScreen}
            isLoading={isLoading}
            declaration={declaration}
            isRequalifying={isRequalifying}
            requalDetails={requalDetails}
            origin={!isDeclarationOrigin}
          />
        )}
        {declaration?.type === TypeSecurite.ACCIDENT_BENIN && (
          <ConfirmationDetailsSubtitle
            isSmallScreen={isSmallScreen}
            isLoading={isLoading}
            declaration={declaration}
          />
        )}
      </AccordionDetails>
    </Accordion>
  );
}

export default DeclarationDetails;
