import { Grid2 as Grid, Skeleton, Typography } from "@mui/material";
import type { GridSize } from "@mui/material";
import DividerTitle from "components/utils/DividerTitle";
import type { ReactElement } from "react";

interface SkeletonInputProps {
  sm?: GridSize;
  variant?:
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "caption"
    | "button"
    | "overline"
    | "inherit";
  width?: string;
}

function SkeletonTitle({ sm, variant, width }: SkeletonInputProps): ReactElement {
  return (
    <Grid size={{ sm: sm ?? 12 }}>
      <DividerTitle>
        <Typography variant={variant ?? "h5"}>
          <Skeleton width={width ?? "200px"} />
        </Typography>
      </DividerTitle>
    </Grid>
  );
}

export default SkeletonTitle;
