import { useState } from "react";
import type { ReactElement } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  Grid2 as Grid,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import type { FieldValues, SubmitHandler } from "react-hook-form";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useData } from "providers/DataProvider";
import excelService from "services/excelService";
import securiteService from "services/securiteService";
import type { Securite } from "models/Securite";
import TypeSecurite from "constants/TypeSecurite";
import { useSnackbar } from "notistack";
import ToastMessages from "constants/ToastMessages";
import DividerTitle from "components/utils/DividerTitle";
import { dateUtil } from "@sdeapps/react-core";

const defaultDate = new Date();
defaultDate.setHours(0, 0, 0, 0);

const allDates = dateUtil.getAllYearsSince(2022);

type ExportForms = {
  selectedYear: string;
};

async function getAndSelectAccidentsBenins(data: ExportForms): Promise<Array<Securite>> {
  const allSecurites: Array<Securite> = await securiteService.getAll();

  return allSecurites.filter((securite) => {
    return (
      dateUtil.getYear(securite.dateEtHeure) === data.selectedYear &&
      securite.type === TypeSecurite.ACCIDENT_BENIN &&
      !securite.isVictimePrestataire
    );
  });
}

type ControlledExportRegistreDialogPartsProps = {
  handleClose: () => void;
};

function ControlledExportRegistreDialogParts({
  handleClose,
}: ControlledExportRegistreDialogPartsProps): ReactElement {
  const { communes, agents } = useData();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { handleSubmit, control } = useForm({
    shouldFocusError: false,
  });

  async function exportExcel(exportData: ExportForms): Promise<void> {
    setLoading(true);

    try {
      const accidentsBenins = await getAndSelectAccidentsBenins(exportData);

      excelService.exportRegistreAccidentsBenins(
        accidentsBenins,
        communes,
        agents,
        `registre_accidents_benins_${exportData.selectedYear}`
      );
    } catch (error) {
      console.error("Export Excel Registre Accidents Bénins failed !", error);
      enqueueSnackbar(ToastMessages.ERROR_RETRY, {
        variant: "error",
      });
    }

    setLoading(false);
    handleClose();
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(exportExcel as SubmitHandler<FieldValues>)}>
      <DialogContent>
        <Grid container size={12} spacing={3} sx={{ maxWidth: "md" }}>
          <Grid size={12}>
            <DividerTitle>
              <Typography variant="subtitle1">Filtres</Typography>
            </DividerTitle>
          </Grid>
          <Grid size={12}>
            <Controller
              name="selectedYear"
              defaultValue=""
              control={control}
              rules={{
                required:
                  "Veuillez choisir une année pour laquelle exporter le Registre des Accidents Bénins",
              }}
              render={({ field: { value, onChange, ref }, fieldState: { error } }) => (
                <TextField
                  label="Année *"
                  value={value}
                  fullWidth
                  select
                  onChange={onChange}
                  error={error != null}
                  helperText={error?.message}
                  inputRef={ref}>
                  {allDates.map((date) => (
                    <MenuItem key={date} value={date}>
                      {date}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" type="submit" loading={loading} endIcon={<FileDownloadIcon />}>
          Télécharger
        </Button>
      </DialogActions>
    </form>
  );
}

export default ControlledExportRegistreDialogParts;
