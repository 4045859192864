import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import { useSnackbar } from "notistack";
import type { SnackbarKey } from "notistack";
import ToastMessages from "constants/ToastMessages";
import { localStorageKeys, routesConfig } from "app-config";
import { Button } from "@mui/material";
import IntroTextModal from "components/utils/PopupHandler/IntroTextModal/IntroTextModal";

function RgpdSnackbarActions({ onAccept }: { onAccept: () => void }): ReactElement {
  return (
    <>
      <Button href={routesConfig.legal.path}>En savoir plus</Button>
      <Button variant="contained" onClick={onAccept}>
        Accepter
      </Button>
    </>
  );
}

enum PopupPhase {
  "INTRO",
  "RGPD",
  "END",
}

/**
 * Gère l'ouverture des différentes popups et snackbars de bienvenue et
 * d'informations au démarrage de l'application.
 */
function PopupHandler(): ReactElement {
  const [shouldOpenIntro, setShouldOpenIntro] = useState(false);
  const [shouldOpenRgpd, setShouldOpenRgpd] = useState(false);
  const [currentPhase, setCurrentPhase] = useState<PopupPhase>();

  const [isIntroModalOpen, setIsIntroModalOpen] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  function openIntro(): void {
    setIsIntroModalOpen(true);
  }

  function openRgpd(): void {
    function onAccept(key: SnackbarKey): void {
      localStorage.setItem(localStorageKeys.RGPD, "true");
      toNextPhase();
      closeSnackbar(key);
    }

    enqueueSnackbar(ToastMessages.INFO_RGPD, {
      variant: "default",
      autoHideDuration: null,
      action: (key) => (
        <RgpdSnackbarActions
          onAccept={() => {
            onAccept(key);
          }}
        />
      ),
    });
  }

  function toNextPhase(): void {
    if (currentPhase == null) {
      setCurrentPhase(0);
    } else {
      setCurrentPhase(currentPhase + 1);
    }
  }

  useEffect(() => {
    setShouldOpenIntro(localStorage.getItem(localStorageKeys.introText) !== "true");
    setShouldOpenRgpd(localStorage.getItem(localStorageKeys.RGPD) !== "true");

    toNextPhase();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    switch (currentPhase) {
      case PopupPhase.INTRO:
        if (shouldOpenIntro) {
          openIntro();
        } else {
          toNextPhase();
        }
        break;
      case PopupPhase.RGPD:
        if (shouldOpenRgpd) {
          openRgpd();
        } else {
          toNextPhase();
        }
        break;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPhase]);

  return (
    <IntroTextModal open={isIntroModalOpen} setOpen={setIsIntroModalOpen} onClose={toNextPhase} />
  );
}

export default PopupHandler;
