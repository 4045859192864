import { localStorageKeys } from "app-config";
import type User from "models/User";

/**
 * Enregistre dans le localStorage les données de l'utilisateur passé
 * en paramètre.
 * @param userInfo User à enregistrer dans le localStorage.
 */
function updateStorageUser(userInfo: User): void {
  localStorage.setItem(localStorageKeys.currentUserData, JSON.stringify(userInfo));
  window.dispatchEvent(new Event("storage"));
}

/**
 * Supprime du localStorage les données du compte actuellement identifié.
 */
function removeUserInfo(): void {
  localStorage.removeItem(localStorageKeys.currentUserData);
  window.dispatchEvent(new Event("storage"));
}

/**
 * Supprime toutes les données du localStorage (à part RGPD et introText) et du
 * sessionStorage puis recharge la page; permet d'éviter les conflits de token de rafraichissement.
 */
function clearLocalAndSessionStorage(): void {
  // eslint-disable-next-line no-console
  console.info("%cCLEANING LOCAL AND SESSION STORAGE", "color: cornflowerblue;");
  const previousRGPD = localStorage.getItem(localStorageKeys.RGPD) ?? false;
  const previousIntroText = localStorage.getItem(localStorageKeys.introText) ?? false;

  localStorage.clear();
  localStorage.setItem(localStorageKeys.RGPD, previousRGPD.toString());
  localStorage.setItem(localStorageKeys.introText, previousIntroText.toString());
  sessionStorage.clear();

  window.dispatchEvent(new Event("storage"));
}

const storageService = {
  updateStorageUser,
  removeUserInfo,
  clearLocalAndSessionStorage,
};
export default storageService;
