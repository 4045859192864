import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import { Container, Grid2 as Grid, useMediaQuery, useTheme } from "@mui/material";
import { useParams, useMatch, useNavigate, useLocation } from "react-router-dom";
import AccidentComplementForm from "components/sections/Forms/AccidentComplementForm";
import AccidentForm from "components/sections/Forms/AccidentForm";
import AccidentTabs from "components/sections/AccidentTabs/AccidentTabs";
import type { Accident } from "models/Accident";
import accidentService from "services/accidentService";
import { useData } from "providers/DataProvider";
import { routesConfig } from "app-config";
import type TypeAccident from "constants/TypeAccident";
import DeclarationFormTitle from "components/sections/Forms/DeclarationFormTitle";
import DeclarationDetails from "components/sections/DeclarationDetails/DeclarationDetails";
import TutoBeginningAccidentPage from "components/sections/Tutos/TutoBeginningAccidentPage";
import Errors from "constants/Errors";
import ForbiddenErrorPage from "components/errors/ForbiddenErrorPage";
import NotFoundErrorPage from "components/errors/NotFoundErrorPage";
import ServerErrorPage from "components/errors/ServerErrorPage";
import ErrorPage from "components/errors/ErrorPage";

function AccidentPage({ typeAccident }: { typeAccident?: TypeAccident }): ReactElement {
  const { declarationId } = useParams();
  const [isDeclarationLoading, setIsDeclarationLoading] = useState(true);
  const [isNewDeclaration, setIsNewDeclaration] = useState(true);
  const [isRequalifying, setIsRequalifying] = useState(false);
  const [accident, setAccident] = useState<Accident>();
  const [error, setError] = useState<Error>();
  const { error: dataError } = useData();
  const location = useLocation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  const activeTab: "complement" | "general" =
    useMatch(routesConfig.accidentComplement.getParameterPath("*") as string) != null
      ? "complement"
      : "general";

  useEffect(() => {
    if (activeTab === "complement" && declarationId == null) {
      navigate(routesConfig.accident.path);
    }

    setIsNewDeclaration(declarationId == null);
    if (declarationId != null) {
      if (declarationId !== accident?.id) {
        void fetchDeclaration(declarationId);
      }
    } else {
      if (location?.state != null) {
        const { requalifyingDeclaration }: { requalifyingDeclaration: Accident | undefined } =
          location.state;

        if (requalifyingDeclaration != null) {
          setIsDeclarationLoading(true);
          setIsRequalifying(true);
          setAccident(requalifyingDeclaration);
        }
        setTimeout(() => {
          setIsDeclarationLoading(false);
        }, 100);
      } else {
        setIsDeclarationLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    if (dataError) {
      setError(new Error());
    }
  }, [dataError]);

  async function fetchDeclaration(id: string): Promise<void> {
    setIsDeclarationLoading(true);

    try {
      const dec = await accidentService.getById(id);
      setAccident(dec);
      setIsDeclarationLoading(false);
    } catch (error: unknown) {
      console.error("Declaration (probably) not found !", error);
      setError(error as Error);
    }
  }

  function updateReferenceDeclaration(newReferenceDeclaration: Accident): void {
    setAccident(newReferenceDeclaration);
    setIsNewDeclaration(false);
  }

  if (error != null) {
    switch (error?.message) {
      case Errors.FORBIDDEN:
        return <ForbiddenErrorPage />;
      case Errors.NOT_FOUND:
        return (
          <NotFoundErrorPage message="Ceci peut arriver lorsqu'une déclaration a été supprimée ou requalifiée, par exemple." />
        );
      case Errors.SERVER_ERROR:
        return <ServerErrorPage />;
      default:
        return <ErrorPage message={error?.message ?? ""} />;
    }
  }

  return (
    <>
      <Container maxWidth="md" sx={{ mb: 2 }}>
        <Grid container size={12} spacing={2}>
          <Grid size={12}>
            <DeclarationFormTitle
              isRequalifying={isRequalifying}
              isNewDeclaration={isNewDeclaration}
              declarationType={typeAccident ?? accident?.type}
              displayNumber={accident?.displayNumber}
              isSmallScreen={isSmallScreen}
            />
          </Grid>
          <Grid size={12}>
            <DeclarationDetails
              isNew={isNewDeclaration}
              isSmallScreen={isSmallScreen}
              isLoading={isDeclarationLoading}
              declaration={accident}
              isRequalifying={isRequalifying}
            />
          </Grid>
          <Grid size={12}>
            <TutoBeginningAccidentPage />
          </Grid>
        </Grid>
      </Container>
      <AccidentTabs
        activeTab={activeTab}
        disableComplement={isNewDeclaration}
        declarationId={declarationId}
        generalNode={
          <AccidentForm
            isNew={isNewDeclaration}
            isRequalification={isRequalifying}
            accident={accident}
            isLoading={isDeclarationLoading}
            updateReferenceDeclaration={updateReferenceDeclaration}
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion, @typescript-eslint/no-non-null-asserted-optional-chain
            typeAccident={typeAccident ?? accident?.type!}
          />
        }
        complementNode={
          <AccidentComplementForm
            accident={accident}
            isLoading={isDeclarationLoading}
            updateReferenceDeclaration={updateReferenceDeclaration}
          />
        }
      />
    </>
  );
}

export default AccidentPage;
