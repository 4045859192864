import {
  type PropsWithChildren,
  type ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import type Ouvrage from "models/Ouvrage";
import type User from "models/User";
import type Commune from "models/Commune";
import graphService from "services/graphService";
import ouvrageService from "services/ouvrageService";
import territoireService from "services/territoireService";
import communeService from "services/communeService";
import { useSnackbar } from "notistack";
import ToastMessages from "constants/ToastMessages";
import DataContext, { type IData } from "contexts/DataContext";
import type { ControlledTextFieldOptions } from "components/inputs/ControlledTextField";

function DataProvider({ children }: PropsWithChildren): ReactElement {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [communes, setCommunes] = useState<Array<Commune>>();
  const [territoires, setTerritoires] = useState<ControlledTextFieldOptions>();
  const [ouvrages, setOuvrages] = useState<Array<Ouvrage>>();
  const [agents, setAgents] = useState<Array<User>>();
  const [error, setError] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (error) {
      enqueueSnackbar(ToastMessages.ERROR_RETRY, {
        variant: "error",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  useEffect(() => {
    setIsLoading(true);

    graphService
      .getAllAgents(["department", "city", "manager", "employeeId"])
      .then(setAgents)
      .catch((error) => {
        console.error("Problem fetching Inital Data : Agents : ", error);
        setError(true);
      });

    communeService
      .getAllCommunes()
      .then(setCommunes)
      .catch((error) => {
        console.error("Problem fetching Inital Data : Communes : ", error);
        setError(true);
      });

    territoireService
      .getAllTextFieldOptions()
      .then(setTerritoires)
      .catch((error) => {
        console.error("Problem fetching Inital Data : Territoires : ", error);
        setError(true);
      });

    ouvrageService
      .getAll()
      .then(setOuvrages)
      .catch((error) => {
        console.error("Problem fetching Inital Data : Ouvrages : ", error);
        setError(true);
      });
  }, []);

  useEffect(() => {
    if (
      communes !== undefined &&
      territoires !== undefined &&
      ouvrages !== undefined &&
      agents !== undefined
    ) {
      setIsLoading(false);
    }
  }, [communes, territoires, ouvrages, agents]);

  const data: IData = useMemo(() => {
    return {
      isLoading,
      communes: communes ?? [],
      territoires: territoires ?? [],
      ouvrages: ouvrages ?? [],
      agents: agents ?? [],
      error,
    };
  }, [isLoading, communes, territoires, ouvrages, agents, error]);

  return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
}

function useData(): IData {
  const context = useContext(DataContext);

  if (context == null) {
    throw new Error("useData must be used within a DataProvider");
  }

  return context;
}

// eslint-disable-next-line react-refresh/only-export-components
export { DataProvider, useData };
