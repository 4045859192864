import { Fragment, type PropsWithChildren, type ReactElement, useEffect, useState } from "react";
import { AuthenticatedTemplate, MsalProvider, UnauthenticatedTemplate } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import type { IPublicClientApplication } from "@azure/msal-browser";
import SecuConnectionHandler from "./SecuConnectionHandler";
import SecuUserProvider from "./SecuUserProvider";
import { authenticationConfig } from "app-config";

let msalInstance: IPublicClientApplication | undefined;

interface SecuAuthentificationProviderProps extends PropsWithChildren {
  unauthorizedPage?: ReactElement;
}

/**
 * Config needed for :
 * - alternative local & session storage clear
 * - alternative retrieval of user (ex: offline Eau-secours utilise localStorage)
 * - ConnectionHandler connection redirect retry additional condition (ex: EauSecours isOnline)
 */
function SecuAuthentificationProvider({
  unauthorizedPage,
  children,
}: SecuAuthentificationProviderProps): ReactElement {
  const [msalInstanceState, setMsalInstanceState] = useState<IPublicClientApplication>();

  useEffect(() => {
    async function initializeMsalInstance(): Promise<void> {
      msalInstance = await PublicClientApplication.createPublicClientApplication(
        authenticationConfig.msal
      );

      setMsalInstanceState(msalInstance);
    }

    void initializeMsalInstance();
  }, []);

  if (msalInstanceState == null) {
    return unauthorizedPage ?? <Fragment />;
  } else {
    return (
      // Provide l'instance de MSAL pour ses enfants avec le hook useMsal
      <MsalProvider instance={msalInstanceState}>
        {/* Lance l'authentification de l'utilisateur */}
        <SecuConnectionHandler />
        {/* Tant que l'utilisateur n'est pas authentifié, on affiche unauthorizedPage */}
        <UnauthenticatedTemplate>{unauthorizedPage}</UnauthenticatedTemplate>
        {/* Une fois authentifié */}
        <AuthenticatedTemplate>
          {/* Provide l'utilisateur et ses roles une fois trouvés. Tant qu'on n'a pas trouvé l'utilisateur,
        affiche unauthorizedPage. Ne render les enfants QUE lorsque l'utilisateur est trouvé */}
          <SecuUserProvider unauthorizedPage={unauthorizedPage}>{children}</SecuUserProvider>
        </AuthenticatedTemplate>
      </MsalProvider>
    );
  }
}

// eslint-disable-next-line react-refresh/only-export-components
export { SecuAuthentificationProvider, msalInstance };
