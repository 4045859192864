import { useCallback, useEffect, useMemo, useState } from "react";
import type { ReactElement } from "react";
import { DataGrid } from "@mui/x-data-grid";
import type { GridCellParams, GridColDef, GridPaginationModel } from "@mui/x-data-grid";
import type User from "models/User";
import StatutCompletion from "constants/StatutCompletion";
import type { Accident, OfflineAccident } from "models/Accident";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import type Commune from "models/Commune";
import { useNavigate } from "react-router-dom";
import { grey } from "@mui/material/colors";
import DeclarationsFilters from "./DeclarationsFilters";
import SortIcons, { SortIconsDown, SortIconsUp } from "./SortIcons";
import { emptyFilters } from "./DeclarationFilter";
import type { DeclarationFilter } from "./DeclarationFilter";
import SkeletonInput from "components/loading/SkeletonInput";
import { routesConfig } from "app-config";
import type { OfflineSecurite, Securite } from "models/Securite";
import ApplicationRoles from "constants/ApplicationRoles";
import Authorization from "components/sections/Authorization";
import { dateUtil } from "@sdeapps/react-core";
import type { DangerGrave, OfflineDangerGrave } from "models/DangerGrave";
import LoadingOverlay from "./DeclarationsGridLoadingOverlay";
import agentService from "services/agentService";
import getDeclarationsGridColumns from "./getDeclarationsGridColumns";
import { useData } from "providers/DataProvider";
import type { OfflineSearchDeclaration, SearchDeclaration } from "./models/SearchDeclaration";
import { useUser } from "providers/Authentication/SecuUserProvider";

function getShortName(
  agents: Array<User>,
  victimeId?: string,
  victimeFirstName?: string,
  victimeLastName?: string,
  isVictimePrestataire: boolean = false
): string {
  if (isVictimePrestataire) {
    return `${victimeLastName} ${victimeFirstName?.substring(0, 1)}.`;
  }

  if (victimeId == null || victimeId === "" || agents.length === 0) {
    return "";
  }

  const name = agentService.getShortNameById(agents, victimeId, victimeFirstName, victimeLastName);

  return name ?? victimeId;
}

function getLongName(
  agents: Array<User>,
  victimeId?: string,
  victimeFirstName?: string,
  victimeLastName?: string,
  isVictimePrestataire: boolean = false
): string {
  if (isVictimePrestataire) {
    return `${victimeLastName} ${victimeFirstName} (externe)`;
  }

  if (victimeId == null || victimeId === "" || agents.length === 0) {
    return "";
  }

  const name = agentService.getDisplayNameById(
    agents,
    victimeId,
    victimeFirstName,
    victimeLastName
  );

  return name ?? victimeId;
}

interface DeclarationsGridProps {
  accidents: Array<Accident>;
  securites: Array<Securite>;
  dgis: Array<DangerGrave>;
  offlineAccidents?: Array<OfflineAccident>;
  offlineSecurites?: Array<OfflineSecurite>;
  offlineDgis?: Array<OfflineDangerGrave>;
  isDeclarationsLoading: boolean;
}

function DeclarationsGrid({
  accidents,
  securites,
  dgis,
  offlineAccidents = [],
  offlineSecurites = [],
  offlineDgis = [],
  isDeclarationsLoading,
}: DeclarationsGridProps): ReactElement {
  const [filters, setFilters] = useState<DeclarationFilter>(emptyFilters);
  const [smallColumns, setSmallColumns] = useState<Array<GridColDef<SearchDeclaration>>>([]);
  const [mediumColumns, setMediumColumns] = useState<Array<GridColDef<SearchDeclaration>>>([]);
  const [largeColumns, setLargeColumns] = useState<Array<GridColDef<SearchDeclaration>>>([]);
  const [areColumnsLoading, setAreColumnsLoading] = useState(true);
  const navigate = useNavigate();
  const { isRoles } = useUser();
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: isRoles(ApplicationRoles.MODERATOR) ? 100 : 10,
  });
  const { agents, communes } = useData();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  // #region Conversion des différents types de déclarations en un même modèle SearchDeclaration
  // plus pratique pour l'affichage et la recherche
  const accidentToSearchDeclaration = useCallback(
    (accident: Accident): SearchDeclaration => {
      const longName = getLongName(
        agents,
        accident.victimeId,
        accident.victimeFirstName,
        accident.victimeLastName,
        accident.isVictimePrestataire
      );
      const searchAccident: SearchDeclaration = {
        id: accident.id,
        dateEtHeure: accident.dateEtHeure,
        idCommune: accident.communeInsee,
        libelleCommune:
          communes.find((v: Commune) => {
            return v.code === accident.communeInsee;
          })?.nom ?? accident.communeName,
        type: accident.type,
        statutCompletion: accident.statutCompletion,
        victimeId: accident.isVictimePrestataire ? longName : accident.victimeId,
        shortVictimeLibelle: getShortName(
          agents,
          accident.victimeId,
          accident.victimeFirstName,
          accident.victimeLastName,
          accident.isVictimePrestataire
        ),
        longVictimeLibelle: longName,
        precisions: accident.precisions,
        getLinkToDeclaration: (params: GridCellParams) => {
          if (
            params.field === "statutCompletion" &&
            params.row.statutCompletion === StatutCompletion.ACompleter
          ) {
            return routesConfig.accidentComplement.getParameterPath(params.id.toString());
          } else {
            return routesConfig.accident.getParameterPath(params.id.toString());
          }
        },
      };

      return searchAccident;
    },
    [agents, communes]
  );

  const securiteToSearchDeclaration = useCallback(
    (securite: Securite): SearchDeclaration => {
      const longName = getLongName(
        agents,
        securite.victimeId,
        securite.victimeFirstName,
        securite.victimeLastName,
        securite.isVictimePrestataire
      );
      const searchSecurite: SearchDeclaration = {
        id: securite.id,
        dateEtHeure: securite.dateEtHeure,
        idCommune: securite.communeInsee,
        libelleCommune:
          communes.find((v: Commune) => {
            return v.code === securite.communeInsee;
          })?.nom ?? securite.communeName,
        type: securite.type,
        victimeId: securite.isVictimePrestataire ? longName : securite.victimeId,
        shortVictimeLibelle: getShortName(
          agents,
          securite.victimeId,
          securite.victimeFirstName,
          securite.victimeLastName,
          securite.isVictimePrestataire
        ),
        longVictimeLibelle: longName,
        precisions: securite.precisions,
        getLinkToDeclaration: (params: GridCellParams) => {
          return routesConfig.securite.getParameterPath(params.id.toString());
        },
      };

      return searchSecurite;
    },
    [agents, communes]
  );

  const dangerToSearchDeclaration = useCallback(
    (danger: DangerGrave): SearchDeclaration => {
      const searchDanger: SearchDeclaration = {
        id: danger.id,
        dateEtHeure: danger.dateEtHeure,
        idCommune: danger.communeInsee,
        libelleCommune:
          communes.find((v: Commune) => {
            return v.code === danger.communeInsee;
          })?.nom ?? danger.communeName,
        type: "DANGER_GRAVE",
        victimeId: danger.creatorId ?? "",
        shortVictimeLibelle: getShortName(agents, danger.creatorId),
        longVictimeLibelle: getLongName(agents, danger.creatorId),
        precisions: danger.description,
        getLinkToDeclaration: () => "",
      };

      return searchDanger;
    },
    [agents, communes]
  );

  const searchAccidents = useMemo(
    (): Array<SearchDeclaration> =>
      accidents.map((accident) => accidentToSearchDeclaration(accident)),
    [accidents, accidentToSearchDeclaration]
  );
  const searchSecurites = useMemo(
    (): Array<SearchDeclaration> =>
      securites.map((securite) => securiteToSearchDeclaration(securite)),
    [securiteToSearchDeclaration, securites]
  );
  const searchDgis = useMemo(
    (): Array<SearchDeclaration> => dgis.map((dgi) => dangerToSearchDeclaration(dgi)),
    [dangerToSearchDeclaration, dgis]
  );

  const offlineSearchAccidents = useMemo(
    (): Array<OfflineSearchDeclaration> =>
      offlineAccidents.map((accident) => {
        return { ...accidentToSearchDeclaration(accident), sending: accident.sending };
      }),
    [offlineAccidents, accidentToSearchDeclaration]
  );
  const offlineSearchSecurites = useMemo(
    (): Array<OfflineSearchDeclaration> =>
      offlineSecurites.map((securite) => {
        return { ...securiteToSearchDeclaration(securite), sending: securite.sending };
      }),
    [offlineSecurites, securiteToSearchDeclaration]
  );
  const offlineSearchDgis = useMemo(
    (): Array<OfflineSearchDeclaration> =>
      offlineDgis.map((dgi) => {
        return { ...dangerToSearchDeclaration(dgi), sending: dgi.sending };
      }),
    [offlineDgis, dangerToSearchDeclaration]
  );
  // #endregion

  // #region Filtrage des déclarations affichées
  const filterDeclarations = useCallback(
    (options: Array<SearchDeclaration>) => {
      function declarationsFilter(dec: SearchDeclaration): boolean {
        let isDeclarationMatching: boolean = true;

        if (filters.dateYear !== "") {
          isDeclarationMatching =
            isDeclarationMatching && dateUtil.getYear(dec.dateEtHeure) === filters.dateYear;
        }
        if (filters.victimeId !== "") {
          isDeclarationMatching = isDeclarationMatching && dec.victimeId === filters.victimeId;
        }
        if (filters.typeDeclaration !== "") {
          isDeclarationMatching = isDeclarationMatching && dec?.type === filters.typeDeclaration;
        }
        if (filters.statutCompletion !== "") {
          isDeclarationMatching =
            isDeclarationMatching && dec.statutCompletion === filters.statutCompletion;
        }
        if (filters.commune !== "") {
          isDeclarationMatching = isDeclarationMatching && dec.idCommune === filters.commune;
        }
        if (filters.precisions !== "") {
          isDeclarationMatching =
            isDeclarationMatching &&
            dec.precisions?.toLowerCase()?.includes(filters.precisions.toLowerCase());
        }

        return isDeclarationMatching;
      }
      return options.filter(declarationsFilter);
    },
    [filters]
  );

  // Déclenchement du filtrage des déclarations
  const filteredDeclarations = useMemo(
    () =>
      filterDeclarations([
        ...searchAccidents,
        ...searchSecurites,
        ...searchDgis,
        ...offlineSearchAccidents,
        ...offlineSearchSecurites,
        ...offlineSearchDgis,
      ]),
    [
      searchAccidents,
      searchDgis,
      offlineSearchAccidents,
      offlineSearchDgis,
      offlineSearchSecurites,
      searchSecurites,
      filterDeclarations,
    ]
  );
  // #endregion

  // #region Gestion des colonnes du tableau
  useEffect(() => {
    const { smallGridColumns, mediumGridColumns, largeGridColumns } =
      getDeclarationsGridColumns(agents);
    setSmallColumns(smallGridColumns);
    setMediumColumns(mediumGridColumns);
    setLargeColumns(largeGridColumns);
    setAreColumnsLoading(false);
  }, [agents]);

  const selectedColumns = useMemo(() => {
    if (isSmallScreen) {
      return smallColumns;
    }
    if (isMediumScreen) {
      return mediumColumns;
    }
    return largeColumns;
  }, [isSmallScreen, isMediumScreen, smallColumns, mediumColumns, largeColumns]);

  if (areColumnsLoading) {
    return <SkeletonInput />;
  }
  // #endregion

  return (
    <Box sx={{ position: "relative" }}>
      {!isSmallScreen && (
        <Authorization roles={ApplicationRoles.MODERATOR}>
          <DeclarationsFilters
            declarations={[...searchAccidents, ...searchSecurites, ...searchDgis]}
            offlineDeclarations={[
              ...offlineSearchAccidents,
              ...offlineSearchSecurites,
              ...offlineSearchDgis,
            ]}
            filters={filters}
            setFilters={setFilters}
            medium={isMediumScreen}
          />
        </Authorization>
      )}
      <DataGrid
        rows={filteredDeclarations}
        columns={selectedColumns}
        disableColumnMenu
        disableRowSelectionOnClick
        loading={isDeclarationsLoading}
        pageSizeOptions={[10, 20, 50, 100]}
        paginationModel={paginationModel}
        onPaginationModelChange={setPaginationModel}
        onCellClick={(params: GridCellParams<SearchDeclaration>) => {
          navigate(params.row.getLinkToDeclaration(params));
        }}
        initialState={{ sorting: { sortModel: [{ field: "dateEtHeure", sort: "desc" }] } }}
        sx={{
          // Sexy border autour du header du tableau
          "&.MuiDataGrid-root": { borderTop: "none" },
          "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
            border: `1px solid ${grey[300]}`,
            borderRadius: "4px",
          },
          // désactive l'outline moche au focus d'une cellule
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus":
            { outline: "none" },
          // efface l'inutile dernier trait vertical dans le header
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator": {
            display: "none",
          },
          // efface les bords droits et gauche du tableau
          borderRight: "none",
          borderLeft: "none",
          "&.MuiDataGrid-root .MuiDataGrid-row": { cursor: "pointer" },
        }}
        slots={{
          loadingOverlay: LoadingOverlay,
          columnSortedDescendingIcon: SortIconsDown,
          columnSortedAscendingIcon: SortIconsUp,
          columnUnsortedIcon: SortIcons,
        }}
      />
    </Box>
  );
}

export default DeclarationsGrid;
