import { Grid2 as Grid } from "@mui/material";
import SkeletonInput from "./SkeletonInput";
import SkeletonTitle from "./SkeletonTitle";
import type { ReactElement } from "react";

function SkeletonFormComplement(): ReactElement {
  return (
    <Grid container rowSpacing={1} columnSpacing={3}>
      <SkeletonInput />
      <SkeletonTitle />
      <SkeletonInput />
      <SkeletonTitle />
      <SkeletonInput />
      <SkeletonInput />
      <SkeletonInput />
      <Grid container size={{ md: 6 }}>
        <SkeletonInput />
      </Grid>
      <Grid container size={{ md: 6 }}>
        <SkeletonInput />
      </Grid>
      <SkeletonTitle />
      <Grid container size={{ md: 6 }}>
        <SkeletonInput />
      </Grid>
      <Grid container size={{ md: 6 }}>
        <SkeletonInput />
      </Grid>
    </Grid>
  );
}

export default SkeletonFormComplement;
