/**
 * Cherche une requête pour une url précise mise dans un cache précis
 * @param url
 * @param cacheName
 * @returns La requête depuis le cache
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
async function match(url: string, cacheName: string): Promise<any> {
  // eslint-disable-next-line no-console
  console.log(`Error getting data from network (${url}), trying cache fallback in '${cacheName}'`);
  try {
    const cache = await caches.open(cacheName);
    return await cache.match(url);
  } catch (error) {
    console.error("Error retrieving data from cache", error);
    throw error;
  }
}

const cacheService = {
  match,
};
export default cacheService;
