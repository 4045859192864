import { useEffect, useRef } from "react";
import type { ReactElement } from "react";
import {
  TextField,
  RadioGroup,
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  Grid2 as Grid,
  Grow,
} from "@mui/material";
import type { ButtonBaseActions } from "@mui/material";
import {
  type DeepMap,
  type FieldError,
  type FieldValues,
  type RefCallBack,
  type UseFormRegister,
  type UseFormSetValue,
  type UseFormUnregister,
} from "react-hook-form";

interface YesNoTextProps {
  value: boolean;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (...event: Array<any>) => void;
  register: UseFormRegister<FieldValues>;
  unregister: UseFormUnregister<FieldValues>;
  textFieldName: string;
  setValue: UseFormSetValue<FieldValues>;
  label: string;
  errors: DeepMap<FieldValues, FieldError>;
  radioRef: RefCallBack;
  isNew: boolean;
  defaultValue?: string;
  readOnly?: boolean;
  textLabel?: string;
  textPlaceholder?: string;
  textRequired?: string;
}

function YesNoText({
  value,
  name,
  onChange,
  register,
  unregister,
  textFieldName,
  setValue,
  label,
  errors,
  radioRef,
  isNew,
  defaultValue,
  readOnly = false,
  textLabel = "",
  textPlaceholder = "",
  textRequired = "",
}: YesNoTextProps): ReactElement {
  const action = useRef<ButtonBaseActions>(null);

  function toggleTextField(newValue: boolean, text?: string): void {
    onChange(newValue);
    if (newValue) {
      setTextFieldValue(text ?? "");
    } else {
      setTextFieldValue("");
      unregister(textFieldName);
    }
  }

  function setTextFieldValue(newValue: string): void {
    setValue(textFieldName, newValue);
  }

  useEffect(() => {
    if (defaultValue != null && defaultValue !== "") {
      toggleTextField(true, defaultValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getRadioDefaultValue(): "true" | "false" | undefined {
    if (isNew) {
      return undefined;
    }
    return defaultValue != null && defaultValue !== "" ? "true" : "false";
  }

  return (
    <Grid container size={12} sx={{ alignItems: "center" }}>
      <Grid sx={{ mb: 1 }} size={12}>
        <FormLabel component="legend">{label}</FormLabel>
      </Grid>
      <Grid size="auto">
        <FormControl component="fieldset" name={name} error={errors[name] != null}>
          <RadioGroup
            defaultValue={getRadioDefaultValue()}
            onChange={(e) => {
              toggleTextField(e.target.value === "true");
            }}
            row>
            <FormControlLabel
              control={
                <Radio
                  slotProps={{ input: { ref: radioRef } }}
                  action={action}
                  // eslint-disable-next-line @typescript-eslint/unbound-method
                  onFocus={action?.current?.focusVisible}
                />
              }
              label="Non"
              value="false"
              disabled={readOnly}
            />
            <FormControlLabel control={<Radio />} label="Oui" value="true" disabled={readOnly} />
          </RadioGroup>
          <FormHelperText>{errors[name]?.message}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid size={{ xs: 12, md: "grow" }}>
        {value && (
          <Grow in={value} unmountOnExit mountOnEnter>
            <TextField
              label={textLabel}
              placeholder={textPlaceholder}
              fullWidth
              multiline
              {...register(textFieldName, { required: textRequired ?? true })}
              error={errors[textFieldName] != null}
              helperText={errors[textFieldName]?.message}
              slotProps={{
                input: { readOnly },
              }}
            />
          </Grow>
        )}
      </Grid>
    </Grid>
  );
}

export default YesNoText;
