import { Collapse } from "@mui/material";
import {
  Children,
  cloneElement,
  type JSXElementConstructor,
  type PropsWithChildren,
  type ReactElement,
  useState,
} from "react";

interface SpoilerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleNode: ReactElement<any, string | JSXElementConstructor<any>>;
  defaultValue?: boolean;
}

/**
 * Un Spoiler qui cache son contenu enfant, et le montre si l'utilisateur clique sur le toggle
 * @param toggleNode Elément qui montre les enfants si on clique dessus
 * @param children les enfants, affichés une fois le toggle activé
 * @param defaultValue booléen déterminant si il faut montrer ou non les enfants, false par défaut
 */
function Spoiler({
  toggleNode,
  children,
  defaultValue = false,
}: PropsWithChildren<SpoilerProps>): ReactElement {
  const [isSpoiled, setIsSpoiled] = useState<boolean>(defaultValue);

  return (
    <>
      {Children.map(toggleNode, (child) =>
        cloneElement(child, {
          onClick: () => {
            setIsSpoiled(!isSpoiled);
          },
        })
      )}
      <Collapse in={isSpoiled}>{children}</Collapse>
    </>
  );
}

export default Spoiler;
