import { Grid2 as Grid, Skeleton } from "@mui/material";
import type { GridSize } from "@mui/material";
import type { ReactElement } from "react";

interface SkeletonInputProps {
  sm?: GridSize;
  xs?: GridSize;
  height?: string;
}

function SkeletonInput({ sm, xs, height }: SkeletonInputProps): ReactElement {
  const defaultHeight: number = 80;

  return (
    <Grid size={{ sm: sm ?? 12, xs: xs ?? 12 }}>
      <Skeleton height={height ?? defaultHeight} />
    </Grid>
  );
}

export default SkeletonInput;
