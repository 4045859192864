import { useEffect } from "react";
import type { PropsWithChildren, ReactElement } from "react";
import { useOnlineStatus } from "providers/OnlineStatusProvider";

function Offline({ children }: PropsWithChildren): ReactElement {
  const { isOnline, checkIfOnline } = useOnlineStatus();

  useEffect(() => {
    checkIfOnline();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isOnline) return <>{children}</>;
  else return <></>;
}

export default Offline;
