import type { ReactElement } from "react";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Controller } from "react-hook-form";
import type { Control } from "react-hook-form";
import { dateUtil } from "@sdeapps/react-core";

interface ControlledTimePickerProps {
  name: string;
  control: Control;
  label: string;
  defaultValue?: string | Date | null;
  readOnly?: boolean;
  rules?: object; // difficile de retranscrire ce type
  defaultHelperText?: string;
  variant?: "outlined" | "standard" | "filled";
  fullWidth?: boolean;
}

/**
 * Un TimePicker de mui controllé par react-hook-forms. Permet à l'utilisateur de
 * sélectionner une heure de la journée (heure et minutes).
 * @param name obligatoire - Nom unique de l'input
 * @param control obligatoire - objet Control du formulaire react-hook-forms
 * https://react-hook-form.com/api/useform/control
 * @param label obligatoire - label de l'input
 * @param defaultValue valeur par défaut de l'input
 * @param readOnly booléen déterminant si l'input en lecture seule
 * @param rules règles de validation de l'input au même format que
 * les options de la méthode register() de react-hook-forms
 * https://react-hook-form.com/api/useform/register#options
 * @param defaultHelperText - texte d'aide à afficher lorsque le champ n'est
 * pas en erreur
 * @param variant - variante graphique de l'input "outlined" | "standard" |
 * "filled", par défaut "outlined"
 * @param fullWidth - booléen déterminant si l'input prend toute la place
 * horizontale disponible
 * @returns Un TimePicker de mui controllé par react-hook-forms
 */
function ControlledTimePicker({
  name,
  control,
  label,
  defaultValue = null,
  readOnly = false,
  rules,
  defaultHelperText,
  variant = "outlined",
  fullWidth = true,
}: ControlledTimePickerProps): ReactElement {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue != null ? dateUtil.getDate(defaultValue) : null}
      rules={rules}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <TimePicker
          value={value}
          onChange={onChange}
          inputRef={ref}
          label={label}
          readOnly={readOnly}
          slotProps={{
            textField: {
              variant,
              fullWidth,
              error: error != null,
              helperText: error?.message ?? defaultHelperText,
            },
          }}
        />
      )}
    />
  );
}

export default ControlledTimePicker;
