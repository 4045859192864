import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import {
  CircularProgress,
  Container,
  Grid2 as Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { defaultErrorHandlingPages } from "components/errors/errorHandling";
import BackButton from "components/buttons/BackButton";
import accidentService from "services/accidentService";
import securiteService from "services/securiteService";
import PhotosInput from "components/inputs/photos/PhotosInput";
import dangerService from "services/dangerService";

type PhotosPageProps = {
  typeDeclaration: "accident" | "securite" | "danger";
};

function PhotosPage({ typeDeclaration }: PhotosPageProps): ReactElement {
  const { declarationId } = useParams();
  const [isPhotosLoading, setIsPhotosLoading] = useState(true);
  const [photoUris, setPhotoUris] = useState<Array<string>>([]);
  const [error, setError] = useState<Error>();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    async function fetchPhotos(): Promise<void> {
      setIsPhotosLoading(true);
      try {
        let urls: Array<string> = [];
        switch (typeDeclaration) {
          case "accident":
            urls = await accidentService.getPhotosById(declarationId ?? "");
            break;
          case "securite":
            urls = await securiteService.getPhotosById(declarationId ?? "");
            break;
          default:
            urls = await dangerService.getPhotosById(declarationId ?? "");
            break;
        }
        if (urls == null) {
          throw new Error("photoService getUriByUrl response undefined");
        }

        setPhotoUris(urls);
        setIsPhotosLoading(false);
      } catch (error: unknown) {
        console.error("Photo (probably) not found !", error);
        setError(error as Error);
      }
    }

    void fetchPhotos();
  }, [declarationId, typeDeclaration]);

  if (error != null) {
    return defaultErrorHandlingPages(error);
  }

  return (
    <Container>
      <Grid container spacing={3}>
        <Grid container size={12} sx={{ alignItems: "center" }}>
          <BackButton toHomePage />
          <Typography variant={isSmallScreen ? "h4" : "h3"} component="div">
            Photos
          </Typography>
        </Grid>
        <Grid container size={12}>
          {isPhotosLoading ? (
            <Grid size={12}>
              <CircularProgress size="150px" />
            </Grid>
          ) : (
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            <PhotosInput value={photoUris} onChange={() => {}} readOnly />
          )}
        </Grid>
      </Grid>
    </Container>
  );
}

export default PhotosPage;
