import { useEffect, useState } from "react";
import type { ReactElement } from "react";
import type User from "models/User";
import TypeAccident from "constants/TypeAccident";
import StatutCompletion from "constants/StatutCompletion";
import {
  Autocomplete,
  Box,
  IconButton,
  InputAdornment,
  OutlinedInput,
  TextField,
  createFilterOptions,
} from "@mui/material";
import type Commune from "models/Commune";
import AgentSelect from "components/inputs/agents/AgentSelect";
import CloseIcon from "@mui/icons-material/Close";
import { useDebouncedCallback } from "use-debounce";
import FilterAutocomplete from "components/declarationsGrid/FilterAutocomplete";
import agentService from "services/agentService";
import type { DeclarationFilter } from "./DeclarationFilter";
import TypeSecurite from "constants/TypeSecurite";
import declarationService from "services/declarationService";
import communeService from "services/communeService";
import { arrayUtil, dateUtil } from "@sdeapps/react-core";
import { useData } from "providers/DataProvider";
import type { OfflineSearchDeclaration, SearchDeclaration } from "./models/SearchDeclaration";

const allFilterDates = dateUtil.getAllYearsSince(2022);

function getAllNotUndefinedInArray<T>(array: Array<T | undefined>): Array<T> {
  const notUndefined: Array<T> = [];
  array.forEach((elem) => {
    if (elem != null) {
      notUndefined.push(elem);
    }
  });
  return notUndefined;
}

interface DeclarationsFiltersProps {
  filters: DeclarationFilter;
  setFilters: (arg: DeclarationFilter) => void;
  declarations: Array<SearchDeclaration>;
  offlineDeclarations?: Array<OfflineSearchDeclaration>;
  medium: boolean;
  precisionFilterDebounce?: number;
}

function DeclarationsFilters({
  filters,
  setFilters,
  declarations,
  offlineDeclarations = [],
  medium,
  precisionFilterDebounce = 500,
}: Readonly<DeclarationsFiltersProps>): ReactElement {
  const [allFilterVictims, setAllFilterVictims] = useState<Array<User>>([]);
  const [allFilterCommunes, setAllFilterCommunes] = useState<Array<Commune>>([]);
  const [precisionsText, setPrecisionsText] = useState<string>("");
  const [communeValue, setCommuneValue] = useState<Commune | null>(null);
  const [agentValue, setAgentValue] = useState<User | null>(null);
  const { agents, communes } = useData();

  // #region Filter setters
  function setFilterDate(filterDate: string | null): void {
    setFilters({ ...filters, dateYear: filterDate ?? "" });
  }

  function setFilterVictim(filterVictim: string | null): void {
    setFilters({ ...filters, victimeId: filterVictim ?? "" });
  }

  function setFilterTypeDeclaration(filterVictim: string | null): void {
    setFilters({ ...filters, typeDeclaration: filterVictim ?? "" });
  }

  function setFilterStatutCompletion(statutCompletion: string | null): void {
    setFilters({ ...filters, statutCompletion: statutCompletion ?? "" });
  }

  function setFilterCommune(commune: Commune | null): void {
    setFilters({ ...filters, commune: commune?.code ?? "" });
  }

  const debouncedSetFilterPrecisions = useDebouncedCallback((precisions: string | null) => {
    setFilters({ ...filters, precisions: precisions ?? "" });
  }, precisionFilterDebounce);

  function setFilterPrecisions(precisions: string | null): void {
    setPrecisionsText(precisions ?? "");
    debouncedSetFilterPrecisions(precisions);
  }
  // #endregion

  function updateVictimsList(
    allDeclarations: Array<SearchDeclaration | OfflineSearchDeclaration>,
    allAgents: Array<User>
  ): void {
    if (allAgents == null || allAgents?.length === 0) return;
    const _agents: Array<User | undefined> = [];
    allDeclarations.forEach((dec: SearchDeclaration) => {
      if (dec.victimeId !== "" && dec.victimeId != null) {
        // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
        const fakeUser: User = {
          id: dec.victimeId,
          displayName: dec.longVictimeLibelle,
        } as User;
        _agents.push(agentService.getById(allAgents, dec.victimeId) ?? fakeUser);
      }
    });

    const notUndefinedAndUniqueVictims = arrayUtil.dedupArray(
      getAllNotUndefinedInArray(_agents),
      (u) => u.id
    );

    setAllFilterVictims(notUndefinedAndUniqueVictims);
  }

  function updateCommunesList(
    allDeclarations: Array<SearchDeclaration | OfflineSearchDeclaration>,
    allCommunes: Array<Commune>
  ): void {
    if (allCommunes == null || allCommunes?.length === 0) return;
    const _communes: Array<Commune | undefined> = [];
    allDeclarations.forEach((dec: SearchDeclaration) => {
      _communes.push(
        allCommunes.find((v: Commune) => v?.code === dec.idCommune) ?? {
          code: dec.idCommune,
          nom: dec.libelleCommune,
        }
      );
    });

    const notUndefinedAndUniqueCommunes = arrayUtil.dedupArray(
      getAllNotUndefinedInArray(_communes),
      (c) => c.code
    );
    notUndefinedAndUniqueCommunes.sort((a, b) => a.nom.localeCompare(b.nom, "fr"));
    setAllFilterCommunes(notUndefinedAndUniqueCommunes);
  }

  // Mise à jour des dropdowns des agents et communes
  useEffect(() => {
    const allDeclarations: Array<SearchDeclaration> = [...declarations, ...offlineDeclarations];
    updateVictimsList(allDeclarations, agents);
    updateCommunesList(allDeclarations, communes);
  }, [declarations, offlineDeclarations, agents, communes]);

  const communeFilterOptions = createFilterOptions({
    trim: true,
    stringify: (option: Commune) => communeService.getFrenchSearchString(option.nom),
  });

  return (
    <Box>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <FilterAutocomplete
          value={filters.dateYear}
          setFilterValue={setFilterDate}
          options={allFilterDates}
          sx={{ width: medium ? 100 : 120 }}
          placeholder="Année"
        />
        <AgentSelect
          value={agentValue}
          onValueChange={setAgentValue}
          onChange={setFilterVictim}
          agents={allFilterVictims}
          fullWidth={false}
          size="small"
          sx={{ flex: 2 }}
        />
        <FilterAutocomplete
          value={filters.typeDeclaration}
          setFilterValue={setFilterTypeDeclaration}
          options={[
            TypeSecurite.ACCIDENT_BENIN,
            TypeSecurite.PRESQU_ACCIDENT,
            TypeSecurite.SITUATION_DANGEREUSE,
            TypeAccident.TRAJET,
            TypeAccident.TRAVAIL,
            "DANGER_GRAVE",
          ]}
          getOptionLabel={declarationService.getTypeDeclarationLabel}
          sx={{ width: 155 }}
          placeholder="Type"
        />
        <FilterAutocomplete
          value={filters.statutCompletion}
          setFilterValue={setFilterStatutCompletion}
          options={[StatutCompletion.ACompleter, StatutCompletion.Terminee]}
          getOptionLabel={(option: string) =>
            option === StatutCompletion.Terminee ? "Terminée" : "À Compléter"
          }
          sx={{ width: 145 }}
          placeholder="État"
        />
        {!medium && (
          <>
            <Autocomplete
              value={communeValue}
              size="small"
              autoHighlight
              openOnFocus
              selectOnFocus
              onChange={(_, value) => {
                setCommuneValue(value);
                setFilterCommune(value);
              }}
              filterOptions={communeFilterOptions}
              getOptionLabel={(option: Commune) => option.nom}
              options={allFilterCommunes}
              sx={{ flex: 1 }}
              renderOption={({ key, ...props }, option: Commune) => (
                <Box component="li" key={key} {...props}>
                  {option.nom}
                </Box>
              )}
              renderInput={(params) => <TextField {...params} placeholder="Commune" />}
            />
            <OutlinedInput
              size="small"
              type="text"
              value={precisionsText}
              onChange={(e) => {
                setFilterPrecisions(e?.target?.value);
              }}
              sx={{ flex: 2 }}
              placeholder="Que s'est-il passé ?"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setFilterPrecisions(null);
                    }}
                    edge="end">
                    {precisionsText !== "" && <CloseIcon fontSize="small" />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </>
        )}
      </Box>
    </Box>
  );
}

export default DeclarationsFilters;
