import {
  type PropsWithChildren,
  type ReactElement,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import InfoBullesContext, { type InfoBullesContextData } from "contexts/InfoBullesContext";

/**
 * Provider d'InfoBulles; stocke le booléen de chaque infoKey qui permettent aux InfoBulles et InfoBulleSwitchs de fonctionner.
 * @param {ReactNode} children les enfants, affichés une fois le toggle activé
 */
function InfoBullesProvider({ children }: PropsWithChildren): ReactElement {
  const [infoSwitches, setInfoSwitches] = useState<Record<string, boolean>>({});

  const getInfoSwitch = useCallback(
    (key: string): boolean => {
      if (infoSwitches[key] == null) {
        const tempInfoSwitches = { ...infoSwitches };
        tempInfoSwitches[key] = false;
        setInfoSwitches(tempInfoSwitches);
        return false;
      }
      return infoSwitches[key];
    },
    [infoSwitches]
  );

  const setInfoSwitch = useCallback(
    (key: string, value: boolean): void => {
      const tempInfoSwitches = { ...infoSwitches };
      tempInfoSwitches[key] = value;
      setInfoSwitches(tempInfoSwitches);
    },
    [infoSwitches]
  );

  const data: InfoBullesContextData = useMemo(() => {
    return {
      infoSwitches,
      getInfoBulleSwitch: getInfoSwitch,
      setInfoBulleSwitch: setInfoSwitch,
    };
  }, [infoSwitches, getInfoSwitch, setInfoSwitch]);

  return <InfoBullesContext.Provider value={data}>{children}</InfoBullesContext.Provider>;
}

function useInfo(): InfoBullesContextData {
  const context = useContext(InfoBullesContext);

  if (context == null) {
    throw new Error("useInfo must be used within a InfoProvider");
  }

  return context;
}

// eslint-disable-next-line react-refresh/only-export-components
export { InfoBullesProvider, useInfo };
