import {
  type PropsWithChildren,
  type ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import OnlineContext from "contexts/OnlineContext";
import declarationSyncService from "services/declarationSyncService";
import onlineService from "services/onlineService";

function OnlineStatusProvider({ children }: PropsWithChildren): ReactElement {
  const [isOnline, setIsOnline] = useState<boolean>(true);

  const checkIfOnline = useCallback(async (): Promise<boolean> => {
    const online = await onlineService.isOnline();
    setIsOnline(online);
    return online;
  }, []);

  useEffect(() => {
    async function checkIfOnlineAndTryToSync(): Promise<void> {
      const online = await checkIfOnline();
      if (online) {
        await declarationSyncService.synchronizeOfflineAndOnline();
      }
    }
    window.addEventListener("online", () => {
      void checkIfOnlineAndTryToSync();
    });
    window.addEventListener("offline", () => {
      void checkIfOnlineAndTryToSync();
    });

    // cleanup quand le composant est démonté; après une grosse résoi, par exemple
    return () => {
      window.removeEventListener("online", () => {
        void checkIfOnlineAndTryToSync();
      });
      window.removeEventListener("offline", () => {
        void checkIfOnlineAndTryToSync();
      });
    };
  }, [checkIfOnline]);

  const data = useMemo(() => {
    return {
      isOnline,
      checkIfOnline: () => {
        void checkIfOnline();
      },
    };
  }, [isOnline, checkIfOnline]);

  return <OnlineContext.Provider value={data}>{children}</OnlineContext.Provider>;
}

function useOnlineStatus(): {
  isOnline: boolean;
  checkIfOnline: () => void;
} {
  const context = useContext(OnlineContext);

  if (context == null) {
    throw new Error("useOnlineStatus must be used within a OnlineStatusProvider");
  }

  return context;
}

// eslint-disable-next-line react-refresh/only-export-components
export { OnlineStatusProvider, useOnlineStatus };
