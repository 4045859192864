import { Button, Container, Grid2 as Grid, Typography } from "@mui/material";
import type { GridSize, SxProps } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ToastMessages from "constants/ToastMessages";
import { routesConfig } from "app-config";
import type { ReactElement } from "react";

const marginTopSx: SxProps = { marginTop: { xs: 4, md: 8 } };

interface ErrorPageProps {
  title?: string;
  subtitle?: string;
  message?: string;
  image?: string;
}

function ErrorPage({ title, subtitle, message, image }: ErrorPageProps): ReactElement {
  const navigate = useNavigate();

  const textContainerSizes =
    image != null
      ? { xs: 10 as GridSize, md: 3 as GridSize, xl: 4 as GridSize }
      : { xs: 12 as GridSize };

  return (
    <Container maxWidth={false}>
      <Grid
        container
        columns={{ xs: 4, md: 8, xl: 12 }}
        spacing={3}
        direction="row-reverse"
        sx={{
          alignItems: "flex-start",
          justifyContent: "center",
        }}>
        {image != null && (
          <Grid size={{ xs: 4, md: 5, xl: 6 }}>
            <img
              style={{ objectFit: "contain", width: "100%" }}
              alt="error illustration"
              src={image}
            />
          </Grid>
        )}
        <Grid container spacing={3} size={textContainerSizes}>
          <Grid size={12}>
            <Typography variant="h3" sx={marginTopSx}>
              {title ?? ":'("}
            </Typography>
            <Typography sx={marginTopSx}>{subtitle ?? ToastMessages.ERROR}</Typography>
          </Grid>
          {message != null && (
            <Grid size={12}>
              <Typography>{message}</Typography>
            </Grid>
          )}
          <Grid sx={marginTopSx} size={12}>
            <Button
              variant="contained"
              onClick={() => {
                navigate(routesConfig.home.path);
                navigate(0);
              }}>
              Retour à l'accueil
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ErrorPage;
