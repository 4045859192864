import { Container, Grid2 as Grid, useMediaQuery, useTheme, Box } from "@mui/material";
import type { SxProps } from "@mui/material";
import { routesConfig } from "app-config";
import type { PropsWithChildren, ReactElement, ReactNode } from "react";
import { Link, type To } from "react-router-dom";
import { ACCIDENTTABSCHILDRENCONTAINERSTYLES, ACCIDENTTABSSTYLES } from "./styles";

type BoxOrLinkProps = PropsWithChildren<{
  isLink: boolean;
  to: string | To;
  sx?: SxProps;
  className: string;
}>;

function BoxOrLink({ isLink, children, to, sx = {}, className }: BoxOrLinkProps): ReactElement {
  if (isLink) {
    return (
      <Box className={className} sx={sx} component={Link} to={to}>
        {children}
      </Box>
    );
  } else {
    return (
      <Box className={className} sx={sx}>
        {children}
      </Box>
    );
  }
}

interface AccidentTabsProps {
  activeTab: "general" | "complement";
  disableComplement?: boolean;
  declarationId?: string;
  generalNode?: ReactNode;
  complementNode?: ReactNode;
}

function AccidentTabs({
  activeTab,
  disableComplement = false,
  declarationId,
  generalNode,
  complementNode,
}: AccidentTabsProps): ReactElement {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const boxStyles: SxProps = {
    fontSize: { xs: "14px", md: "1em" },
    padding: { xs: "10px", md: "20px" },
  };

  return (
    <>
      <Grid
        container
        className="SDEA-accident-tabs-root"
        sx={{
          ...ACCIDENTTABSSTYLES,
          justifyContent: "center",
        }}>
        <Grid container size={12} sx={{ maxWidth: "md" }}>
          <Grid
            size={6}
            className={`SDEA-accident-tab-grid-item SDEA-accident-tab-general ${
              activeTab === "general" && "SDEA-accident-tab-active"
            }`}>
            <BoxOrLink
              isLink={declarationId != null}
              to={routesConfig.accident.getParameterPath(declarationId ?? "")}
              sx={boxStyles}
              className="SDEA-accident-tab-box">
              {`${!isSmallScreen ? "1. " : ""}Informations générales`.toUpperCase()}
            </BoxOrLink>
          </Grid>
          <Grid
            size={6}
            className={`SDEA-accident-tab-grid-item SDEA-accident-tab-complement ${
              activeTab === "complement" && "SDEA-accident-tab-active"
            } ${disableComplement && "SDEA-accident-tab-disabled"}`}>
            {/* Cette Box devient un Link vers le complement si il n'est pas disablé */}
            <BoxOrLink
              isLink={!disableComplement}
              to={routesConfig.accidentComplement.getParameterPath(declarationId ?? "")}
              sx={boxStyles}
              className="SDEA-accident-tab-box">
              {`${!isSmallScreen ? "2. " : ""}Informations complémentaires`.toUpperCase()}
            </BoxOrLink>
          </Grid>
        </Grid>
      </Grid>
      <Container
        maxWidth="md"
        className="SDEA-accident-tabs-children-container"
        sx={ACCIDENTTABSCHILDRENCONTAINERSTYLES}>
        {activeTab === "general" ? generalNode : complementNode}
      </Container>
    </>
  );
}

export default AccidentTabs;
