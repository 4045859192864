import { cacheConfig } from "app-config";
import type Commune from "models/Commune";
import networkService from "./networkService";

const ALL_DEPARTEMENTS = ["67", "57", "68"];
const cacheName = cacheConfig.communes.name;

async function getCommune(nomCommune: string): Promise<Array<Commune>> {
  let communes: Array<Commune> = [];
  const fetches = ALL_DEPARTEMENTS.map(async (dep) => getCommuneInDep(nomCommune, dep));

  await Promise.all(fetches).then((res) => {
    communes = communes.concat(...res);
    communes = renameDuplicates(communes);
  });

  return communes;
}

/**
 * Repère les communes qui possèdent le même nom et y ajoute entre parenthèses le code postal
 * ex: Patatesheim et Patatesheim deviennent Patatesheim (68666) et Patatesheim (67666)
 * @returns Liste des communes avec les noms dédupliqués
 */
function renameDuplicates(array: Array<Commune>): Array<Commune> {
  const l = array.length;
  const newArray = [];

  for (let index = 0; index < l; index++) {
    // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
    const e = array.shift() as Commune;
    const b = array.find((v, i, a) => {
      if (v.nom === e.nom) {
        a[i].nom += a[i].code.length <= 3 ? ` (67${a[i].code})` : ` (${a[i].code})`;
        return true;
      }
      return false;
    });
    if (b !== undefined) {
      // trouvé un doublon !
      e.nom += e.code.length <= 3 ? ` (67${e.code})` : ` (${e.code})`;
    }
    newArray.push(e);
  }
  return newArray;
}

async function getCommuneInDep(nomCommune: string, dep: string): Promise<Array<Commune>> {
  const url = `https://geo.api.gouv.fr/communes?nom=${nomCommune}&codeDepartement=${dep}&fields=code,nom`;
  const options: RequestInit = {
    method: "GET",
  };
  return networkService.fetchWithCacheFallback(url, options, cacheName).then((res) => res.json());
}

async function getAllCommunesInDep(dep: string): Promise<Array<Commune>> {
  const url = `https://geo.api.gouv.fr/communes?codeDepartement=${dep}&fields=code,nom`;
  const options: RequestInit = {
    method: "GET",
  };
  return networkService.fetchWithCacheFallback(url, options, cacheName).then((res) => res.json());
}

async function getAllCommunes(): Promise<Array<Commune>> {
  let communes: Array<Commune> = [];
  const fetches = ALL_DEPARTEMENTS.map(async (dep) => getAllCommunesInDep(dep));

  await Promise.all(fetches).then((res) => {
    communes = communes.concat(...res);
    communes = renameDuplicates(communes);
  });

  return communes;
}

/**
 * Renvoie une chaîne de charactères, suivie d'elle-même avec les charactères
 * spéciaux 'œ', 'æ' ainsi que les accents remplacés par leurs équivalents plus
 * facilement écrivables avec des claviers normaux, "oe" et "ae".
 * Sert à assouplir la recherche dans cette chaîne par un utilisateur.
 * @param nom
 */
function getFrenchSearchString(nom: string): string {
  return `${nom} ${nom
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replaceAll(/œ/gi, "oe")
    .replaceAll(/æ/gi, "ae")}`;
}

const communeService = {
  getCommune,
  getAllCommunes,
  getFrenchSearchString,
};

export default communeService;
